import { axiosInstanceWithUserAuth } from "./axios-instance";

export const getAccountStats = async () => {
  try {
    const response = await axiosInstanceWithUserAuth.get(`/account/stats`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching user heroes: ${error}`);
  }
};

export const deleteAccount = async () => {
  try {
    const response = await axiosInstanceWithUserAuth.delete(`/account`);
    return response;
  } catch (error) {
    throw new Error(`Error fetching user heroes: ${error}`);
  }
};
