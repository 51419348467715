import React from 'react';
import { StorySettings } from '../../types/StorySettings';
import { useTranslation } from 'react-i18next';

interface StorySettingsStepProps {
  settings: StorySettings;
  onSettingsChange: (settings: StorySettings) => void;
}

export const StorySettingsStep: React.FC<StorySettingsStepProps> = ({
  settings,
  onSettingsChange
}) => {
  const { t } = useTranslation();
  return (
    <div className="space-y-6">

      {/* Settings spécifiques au mode */}
      {settings.isInteractive ? (
        <div className="space-y-4">
          <div>
            <label className="block text-lg text-white/80 mb-2">Difficulté</label>
            <select 
              value={settings.difficulty}
              onChange={(e) => onSettingsChange({ 
                ...settings, 
                difficulty: e.target.value as StorySettings['difficulty'],
                ageRange: e.target.value === 'easy' ? '4-6' : e.target.value === 'medium' ? '7-9' : '10-12'
              })}
              className="w-full p-3 bg-black/20 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none text-white/80"
            >
              <option value="easy">Facile (4-6 ans)</option>
              <option value="medium">Moyen (7-9 ans)</option>
              <option value="hard">Difficile (10-12 ans)</option>
            </select>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <div>
            <label className="block text-lg text-white/80 mb-4">Durée de l'histoire</label>
            <div className="space-y-4">
              <input 
                type="range" 
                min="2" 
                max="20" 
                step="2"
                value={settings.duration}
                onChange={(e) => onSettingsChange({ ...settings, duration: Number(e.target.value) })}
                className="w-full accent-purple-500"
              />
              <div className="flex justify-between text-white/60 text-sm">
                <span>2 min</span>
                <span>{settings.duration} {t('minutes')}</span>
                <span>20 min</span>
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <label className="block text-lg text-white/80 mb-2">Visibilité</label>
            <div className="flex gap-4">
              <button
                onClick={() => onSettingsChange({ ...settings, isPublic: true })}
                className={`
                  flex-1 p-3 rounded-lg transition-all border
                  ${settings.isPublic 
                    ? 'bg-purple-500/20 border-purple-500/50' 
                    : 'border-white/10 hover:bg-white/5'}
                `}
              >
                Public
              </button>
              <button
                onClick={() => onSettingsChange({ ...settings, isPublic: false })}
                className={`
                  flex-1 p-3 rounded-lg transition-all border
                  ${!settings.isPublic 
                    ? 'bg-purple-500/20 border-purple-500/50' 
                    : 'border-white/10 hover:bg-white/5'}
                `}
              >
                Privé
              </button>
            </div>
            <p className="text-sm text-white/60 mt-2">
              {settings.isPublic 
                ? "L'histoire sera visible dans le catalogue public"
                : "L'histoire sera uniquement visible par vous"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};