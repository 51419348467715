import React, { useState, useEffect, useRef } from 'react';

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
}

type TooltipRef = {
  tooltip: HTMLDivElement | null;
  trigger: HTMLElement | null;
};

const Tooltip: React.FC<TooltipProps> = ({ children, content, placement = 'top', align = 'center' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const refs = useRef<TooltipRef>({
    tooltip: null,
    trigger: null,
  });

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsVisible(true);
    };

    const handleMouseLeave = () => {
      setIsVisible(false);
    };

    if (refs.current.trigger) {
      refs.current.trigger.addEventListener('mouseenter', handleMouseEnter);
      refs.current.trigger.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (refs.current.trigger) {
        refs.current.trigger.removeEventListener('mouseenter', handleMouseEnter);
        refs.current.trigger.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible && refs.current.tooltip && refs.current.trigger) {
      const triggerRect = refs.current.trigger.getBoundingClientRect();
      const tooltipRect = refs.current.tooltip.getBoundingClientRect();

      let left = triggerRect.left;
      let top = triggerRect.top;

      switch (placement) {
        case 'top':
          top = triggerRect.top - tooltipRect.height - 8;
          left = triggerRect.left - (tooltipRect.width - triggerRect.width) / 2;
          break;
        case 'right':
          left = triggerRect.right + 8;
          top = triggerRect.top - (tooltipRect.height - triggerRect.height) / 2;
          break;
        case 'bottom':
          top = triggerRect.bottom + 8;
          left = triggerRect.left - (tooltipRect.width - triggerRect.width) / 2;
          break;
        case 'left':
          left = triggerRect.left - tooltipRect.width - 8;
          top = triggerRect.top - (tooltipRect.height - triggerRect.height) / 2;
          break;
      }

      switch (align) {
        case 'start':
          left = triggerRect.left;
          break;
        case 'center':
          left = triggerRect.left - (tooltipRect.width - triggerRect.width) / 2;
          break;
        case 'end':
          left = triggerRect.right - tooltipRect.width;
          break;
      }

      refs.current.tooltip.style.left = `${left}px`;
      refs.current.tooltip.style.top = `${top}px`;
    }
  }, [isVisible, placement, align]);

  return (
    <div className="relative inline-block">
      <div
        ref={(trigger) => {
          refs.current.trigger = trigger;
        }}
      >
        {children}
      </div>
      {isVisible && (
        <div
          ref={(tooltip) => {
            refs.current.tooltip = tooltip;
          }}
          className="bg-gray-800 text-white px-3 py-2 rounded shadow-lg absolute z-50 whitespace-nowrap"
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;