import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { HeroesPage } from './pages/HeroesPage';
import { LoginPage } from './pages/LoginPage';
import { AuthProvider } from './components/AuthContext/AuthContext';
import './styles/App.css'
import './styles/global.css'
import { AccountPage } from './pages/AccountPage';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { SignupPage } from './pages/SignupPage';
import { StoriesPage } from './pages/StoriesPage';
import { MyStoriesPage } from './pages/MyStoriesPage';
import { CreateStoryPage } from './pages/CreateStoryPage';
import StoryPage from './pages/StoryPage';
import { SettingsPage } from './pages/SettingsPage';
import TestPage from './pages/TestPage';

PiwikPro.initialize('f652a00f-1223-4511-a12f-a78698b74573', 'https://histoirepourdormir.piwik.pro');

const App = () => (
  <Router>
    <AuthProvider>
      <Routes>
        <Route path="/test" element={<TestPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/stories" element={<StoriesPage />} />
        <Route path="/story/:uuid" element={<StoryPage />} />
        <Route path="/my-stories" element={<MyStoriesPage />} />
        <Route path="/create" element={<CreateStoryPage />} />
        <Route path="/heroes" element={<HeroesPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/account/settings" element={<SettingsPage />} />
      </Routes>
    </AuthProvider>
  </Router>
);

ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'));
