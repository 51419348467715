import { Heroes } from "../types/Hero";
import { Story } from "../types/Story";

export const fromApi = (story: any): Story => {
  const heroes = story.heroes
    ? story.heroes.map((item: Heroes) => item.heroes)
    : story.story_heroes
    ? story.story_heroes.map((item: Heroes) => item.heroes)
    : [];
  return {
    uuid: story.uuid,
    title: story.title,
    summary: story.summary,
    status: story.status,
    createdAt: story.created_at,
    lastPlayed: "",
    details: {
      type: story.type,
      context: story.original_prompt,
      heroes,
      tone: story.tone,
      theme: story.theme,
      educational_elements: story.educational_elements,
      moral_values: story.moral_values,
      settings: {
        isPublic: story.is_public,
        isInteractive: story.is_interactive,
        isArchived: story.is_archived,
        ageRange: story.age_range,
        difficulty: story.difficulty,
        duration: story.duration ?? 0,
        language: story.language,
      },
      chapters: story?.chapters ?? [],
      illustration_file_url: story.illustration_file_url ?? null,
    },
    playCount: story.playCount ?? 0,
    creator_user_id: story.profile_uuid ?? null,
  };
};
