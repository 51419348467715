import { Globe } from "lucide-react";
import { AppLayout } from "../components/AppLayout/AppLayout";
import { LanguageSwitcher } from "../components/LanguageSwitcher/LanguageSwitcher";

export const SettingsPage: React.FC = () => {
  
    return (
      <AppLayout>
        <div className="min-h-screen bg-black text-white">
          {/* Background effects */}
          <div className="relative z-10 max-w-3xl mx-auto px-6 py-12">
            <h1 className="text-2xl font-bold mb-8">Paramètres</h1>
  
            {/* Langue */}
            <section className="mb-8">
              <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
                <Globe className="text-white/60" />
                Langue
              </h2>
              <LanguageSwitcher />
            </section>
          </div>
        </div>
      </AppLayout>
    );
};