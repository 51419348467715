import React, { useEffect, useState } from 'react';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../components/AuthContext/AuthContext';
import {
  User,
  Crown,
  Clock,
  BookOpen,
  Wand2,
  ExternalLink,
  Mail,
  Lock,
  Globe,
  Trash2,
  ChevronRight,
  Flame
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { DeleteAccountModal } from '../components/Account/DeleteAccountModal';
import Select from 'react-select';
import { languagesOptions } from '../utils/variables';
import { EmailAccountModal } from '../components/Account/EmailAccountModal';
import { PasswordAccountModal } from '../components/Account/PasswordAccountModal';
import { deleteAccount, getAccountStats } from '../api/account';
import { TextLoading } from '../components/Loading/TextLoading';

interface StatsProps {
  stories_count: number;
  heroes_count: number;
  stories_readed_count: number;
  stories_readed_duration_total: number;
  stories_adventures_started: number;
  stories_adventures_finished: number;
  stories_duration_total: number;
}

const StatsSection: React.FC<{ stats: StatsProps, loading: boolean }> = ({ stats, loading }) => {
  const { t } = useTranslation()
    
  return <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
    <div className="backdrop-blur-sm bg-black/30 border border-white/10 rounded-lg p-4">
      <div className="flex items-center gap-2 text-white/60 mb-2">
        <Wand2 size={16} />
        <span className="text-sm">{loading ? <TextLoading text={t('account.computing_in_progress')}></TextLoading> : t('account.stories_created')}</span>
      </div>
      <div className="text-xl font-medium text-white">{stats.stories_count} ({stats.stories_duration_total} {t('minutes')})</div>
    </div>
    <div className="backdrop-blur-sm bg-black/30 border border-white/10 rounded-lg p-4">
      <div className="flex items-center gap-2 text-white/60 mb-2">
        <BookOpen size={16} />
        <span className="text-sm">{loading ? <TextLoading text={t('account.computing_in_progress')}></TextLoading> : t('account.stories_readed')}</span>
      </div>
      <div className="text-xl font-medium text-white">{stats.stories_readed_count} ({stats.stories_readed_duration_total} {t('minutes')})</div>
    </div>
    <div className="backdrop-blur-sm bg-black/30 border border-white/10 rounded-lg p-4">
      <div className="flex items-center gap-2 text-white/60 mb-2">
        <User size={16} />
        <span className="text-sm">{loading ? <TextLoading text={t('account.computing_in_progress')}></TextLoading> : t('account.heroes_created')}</span>
      </div>
      <div className="text-xl font-medium text-white">{stats.heroes_count}</div>
    </div>
    <div className="backdrop-blur-sm bg-black/30 border border-white/10 rounded-lg p-4">
      <div className="flex items-center gap-2 text-white/60 mb-2">
        <Flame size={16} />
        <span className="text-sm">{loading ? <TextLoading text={t('account.computing_in_progress')}></TextLoading> : t('account.adventures_created')}</span>
      </div>
      <div className="text-xl font-medium text-white">
        {stats.stories_adventures_started ?
          <>`${stats.stories_adventures_finished} / ${stats.stories_adventures_started}` ({((stats.stories_adventures_finished / stats.stories_adventures_started) * 100).toFixed(0)})</>
          : 0
        }
      </div>
    </div>
  </div>
};

const AccountSettings: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { userProfile, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const handleEmailUpdate = async (newEmail: string) => {
    // TODO: Implémentation de la mise à jour de l'email
  };

  const handlePasswordUpdate = async (currentPassword: string, newPassword: string) => {
    // TODO: Implémentation de la mise à jour du mot de passe
  };

  const handleDeleteAccount = async () => {
    try {
      const result = await deleteAccount();
      if (result) {
        setIsAuthenticated(false)
        navigate('/');
      } else {
        console.log('TODO: erreur pendant la suppression');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const selectStyles = {
    control: (base: any) => ({
      ...base,
      background: 'rgba(255, 255, 255, 0.05)',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      '&:hover': {
        borderColor: 'rgba(255, 255, 255, 0.2)'
      }
    }),
    menu: (base: any) => ({
      ...base,
      background: 'rgba(0, 0, 0, 0.9)',
      backdropFilter: 'blur(10px)'
    }),
    option: (base: any, state: any) => ({
      ...base,
      background: state.isFocused ? 'rgba(139, 92, 246, 0.1)' : 'transparent',
      '&:hover': {
        background: 'rgba(139, 92, 246, 0.2)'
      },
      color: 'white'
    }),
    singleValue: (base: any) => ({
      ...base,
      color: 'white'
    })
  };

  return (
    <div className="space-y-6">
      <div className="backdrop-blur-sm bg-black/30 border border-white/10 rounded-lg divide-y divide-white/10">
        {/* Email */}
        <button
          onClick={() => setShowEmailModal(true)}
          className="w-full p-4 flex items-center justify-between hover:bg-white/5 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Mail size={20} className="text-white/60" />
            <div className="text-left">
              <div className="text-sm font-medium">{t('account.modify_email')}</div>
              <div className="text-sm text-white/60">{t('account.change_email_connection')}</div>
            </div>
          </div>
          <ChevronRight size={16} className="text-white/60" />
        </button>

        {/* Mot de passe */}
        <button
          onClick={() => setShowPasswordModal(true)}
          className="w-full p-4 flex items-center justify-between hover:bg-white/5 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Lock size={20} className="text-white/60" />
            <div className="text-left">
              <div className="text-sm font-medium">{t('account.modify_password')}</div>
              <div className="text-sm text-white/60">{t('account.update_password')}</div>
            </div>
          </div>
          <ChevronRight size={16} className="text-white/60" />
        </button>

        {/* Langue */}
        <div className="p-4">
          <div className="flex items-center gap-3 mb-3">
            <Globe size={20} className="text-white/60" />
            <div>
              <div className="text-sm font-medium">{t('account.language')}</div>
              <div className="text-sm text-white/60">{t('account.update_language')}</div>
            </div>
          </div>
          <Select
            options={languagesOptions}
            value={languagesOptions.find(option => option.value === i18n.language)}
            onChange={(option) => option && i18n.changeLanguage(option.value)}
            styles={selectStyles}
            className="w-full"
            isSearchable={false}
            menuPlacement="top"
          />
        </div>

        {/* Supprimer le compte */}
        <button
          onClick={() => setShowDeleteModal(true)}
          className="w-full p-4 flex items-center gap-3 text-red-400 hover:bg-red-500/5 transition-colors"
        >
          <Trash2 size={20} />
          <div className="text-left">
            <div className="text-sm font-medium">{t('account.delete_account')}</div>
            <div className="text-sm opacity-60">{t('account.delete_account_warning')}</div>
          </div>
        </button>
      </div>
      <>
        {showEmailModal && (
          <EmailAccountModal
            currentEmail={userProfile?.email || ''}
            onClose={() => setShowEmailModal(false)}
            onConfirm={handleEmailUpdate}
          />
        )}

        {showPasswordModal && (
          <PasswordAccountModal
            onClose={() => setShowPasswordModal(false)}
            onConfirm={handlePasswordUpdate}
          />
        )}

        {showDeleteModal && (
          <DeleteAccountModal
            onClose={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteAccount}
          />
        )}
      </>
    </div>
  );
};

export const AccountPage: React.FC = () => {
  const { userProfile, loadingProfile } = useAuth();
  const [stats, setStats] = useState<StatsProps>({
    stories_count: 0,
    heroes_count: 0,
    stories_readed_count: 0,
    stories_readed_duration_total: 0,
    stories_adventures_finished: 0,
    stories_adventures_started: 0,
    stories_duration_total: 0
  });
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoadingStats(true)
        const stats = await getAccountStats();
        setStats(stats)
      } catch (error) {
        console.log('Error fetching account stats', error);
      } finally {
        setLoadingStats(false)
      }
    } 

    fetchStats()
  }, []);

  const handleOpenCustomerPortal = async () => {
    window.location.href = 'https://billing.stripe.com/p/session/xxx';
  };

  return (
    <AppLayout>
      <div className="min-h-screen bg-black text-white">
        <div className="max-w-7xl mx-auto px-6 py-8">
          {/* Header */}
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-2xl font-bold text-white">{loadingProfile ? <TextLoading text={t('account.loading_account')}></TextLoading> : t('account.my_account')}</h1>
              <p className="text-white/60">{userProfile?.email}</p>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={handleOpenCustomerPortal}
                className="flex items-center gap-2 px-4 py-2 bg-purple-500/20 hover:bg-purple-500/30 rounded-lg transition-colors"
              >
                <Crown size={16} />
                {t('account.manage_subscription')}
                <ExternalLink size={14} className="ml-1" />
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="space-y-8">
            {/* Progress bar */}
            <div className="backdrop-blur-sm bg-black/30 border border-white/10 rounded-lg p-6">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-white/60">{t('account.monthly_stories')}</span>
                <span className="text-sm font-medium">{stats.stories_count}/10</span>
              </div>
              <div className="h-2 bg-white/10 rounded-full overflow-hidden">
                <div
                  className="h-full bg-gradient-to-r from-purple-500 to-blue-500"
                  style={{ width: `${(stats.stories_count/10)*100}%` }}
                />
              </div>
            </div>

            {/* Stats */}
            <StatsSection stats={stats} loading={loadingStats} />

            {/* Account Settings */}
            {!loadingProfile && <AccountSettings />}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default AccountPage;