import React from 'react';
import { useTranslation } from 'react-i18next';

interface LoadingProps {
  size?: number;
  withText?: boolean;
  variant?: 'gradient' | 'pulse' | 'dots';
}

export const Loading: React.FC<LoadingProps> = ({ 
    size = 200, 
    withText = true,
    variant = 'gradient'
  
}) => {
  const { t } = useTranslation();

  const renderLoader = () => {
    switch(variant) {
      case 'gradient':
        return (
          <div className="flex flex-col items-center justify-center gap-6" style={{ width: size }}>
            <div className="relative">
              <div className="absolute inset-0 rounded-full bg-gradient-to-r from-violet-500 via-fuchsia-500 to-purple-500 animate-spin" style={{ 
                width: size, 
                height: size,
                filter: 'blur(15px)',
                opacity: 0.5
              }} />
              <div className="relative rounded-full bg-gradient-to-r from-violet-500 via-fuchsia-500 to-purple-500 animate-spin" style={{ 
                width: size, 
                height: size 
              }}>
                <div className="absolute inset-1 rounded-full bg-black" />
              </div>
            </div>
            {withText && (
              <div className="text-white/70 font-medium animate-pulse">
                {t('loading')}
              </div>
            )}
          </div>
        );

      case 'pulse':
        return (
          <div className="flex flex-col items-center justify-center gap-6" style={{ width: size }}>
            <div className="relative" style={{ width: size, height: size }}>
              <div className="absolute inset-0 rounded-full bg-purple-500/20 animate-ping" />
              <div className="absolute inset-0 rounded-full bg-gradient-to-tr from-violet-500 to-fuchsia-500 animate-pulse" />
              <div className="absolute inset-2 rounded-full bg-black" />
              <div className="absolute inset-4 rounded-full bg-gradient-to-br from-violet-500/20 to-fuchsia-500/20 animate-pulse" />
            </div>
            {withText && (
              <div className="text-white/70 font-medium animate-pulse">
                {t('loading')}
              </div>
            )}
          </div>
        );

      case 'dots':
        return (
          <div className="flex flex-col items-center justify-center gap-6" style={{ width: size }}>
            <div className="flex gap-2">
              {[...Array(3)].map((_, i) => (
                <div 
                  key={i}
                  className="bg-gradient-to-br from-violet-500 to-fuchsia-500 rounded-full animate-bounce"
                  style={{ 
                    width: size / 5, 
                    height: size / 5,
                    animationDelay: `${i * 0.15}s`
                  }}
                >
                  <div className="w-full h-full rounded-full bg-gradient-to-br from-violet-500/20 to-fuchsia-500/20 animate-pulse" />
                </div>
              ))}
            </div>
            {withText && (
              <div className="text-white/70 font-medium animate-pulse">
                {t('loading')}
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="relative">
      {renderLoader()}
    </div>
  );
};