import { t } from "i18next";
import { X } from "lucide-react";
import { useState } from "react";

interface EmailModalProps {
  currentEmail: string;
  onClose: () => void;
  onConfirm: (newEmail: string) => Promise<void>;
}

export const EmailAccountModal: React.FC<EmailModalProps> = ({ currentEmail, onClose, onConfirm }) => {
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (newEmail !== confirmEmail) {
      setError(t('account.email_mismatch'));
      return;
    }

    if (newEmail === currentEmail) {
      setError(t('account.email_same_as_current'));
      return;
    }

    setLoading(true);
    try {
      await onConfirm(newEmail);
      onClose();
    } catch (error) {
      console.error('Error updating email:', error);
      setError(t('account.email_update_error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/60 backdrop-blur-sm" onClick={onClose} />
      <div className="relative w-full max-w-md bg-black/80 backdrop-blur-md rounded-xl border border-white/10 p-6 m-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">{t('account.modify_email')}</h2>
          <button 
            onClick={onClose} 
            className="p-2 hover:bg-white/10 rounded-lg transition-all"
            aria-label={t('general.close')}
          >
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm text-white/80 mb-2">{t('account.current_email')}</label>
            <input
              type="email"
              value={currentEmail}
              disabled
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg opacity-50"
            />
          </div>

          <div>
            <label className="block text-sm text-white/80 mb-2">{t('account.new_email')}</label>
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none"
              placeholder={t('account.enter_new_email')}
              required
            />
          </div>

          <div>
            <label className="block text-sm text-white/80 mb-2">{t('account.confirm_new_email')}</label>
            <input
              type="email"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:border-purple-500/50 focus:outline-none"
              placeholder={t('account.confirm_new_email_placeholder')}
              required
            />
          </div>

          {error && (
            <div className="text-red-400 text-sm p-2 rounded bg-red-500/10">
              {error}
            </div>
          )}

          <div className="flex justify-end gap-4 pt-4 border-t border-white/10">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded-lg hover:bg-white/10 transition-all"
            >
              {t('general.cancel')}
            </button>
            <button
              type="submit"
              disabled={loading || !newEmail || !confirmEmail}
              className="px-4 py-2 bg-purple-500/20 hover:bg-purple-500/30 rounded-lg transition-all 
                       disabled:opacity-50 disabled:hover:bg-purple-500/20"
            >
              {loading ? t('general.updating') : t('general.update')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};