import { useEffect, useState } from "react";
import { Message } from "../../types/Message";
import { 
  Book, 
  Clock, 
  Gamepad, 
  Heart, 
  Lightbulb, 
  Map, 
  ScrollText, 
  Theater, 
  Users, 
  ZoomIn, 
  ZoomOut, 
  ChevronDown,
  ChevronUp,
  BookMarked,
  GraduationCap,
  Crown,
  Moon,
  Sun
} from "lucide-react";
import { Hero, Heroes } from "../../types/Hero";
import { Story } from "../../types/Story";
import { Chapter } from "../../types/Chapter";
import { Loading } from "../Loading/Loading";
import { useTranslation } from "react-i18next";
import { createIllustration, readStory } from "../../api/stories";
import { useAuth } from "../AuthContext/AuthContext";

const StoryReader: React.FC<{ 
  currentStory: Story;
}> = ({ currentStory }) => {
  const lsStoryIsFontSize = localStorage.getItem('story_is_font_size') || '16';
  const lsStoryIsUppercase = localStorage.getItem('story_is_uppercase') === 'true' ? true : false || false;
  const lsStoryIsDarkTheme = localStorage.getItem('story_is_dark_theme') === 'true' ? true : false || false;
  const { isAuthenticated, userProfile } = useAuth();
  const { t } = useTranslation();
  const [loadingIllustration, setLoadingIllustration] = useState(false);
  const [story, setStory] = useState<Story>(currentStory);
  const [fontSize, setFontSize] = useState(parseInt(lsStoryIsFontSize));
  const [isUppercase, setIsUppercase] = useState(lsStoryIsUppercase);
  const [isDarkTheme, setIsDarkTheme] = useState(lsStoryIsDarkTheme);
  const [showMobileInfo, setShowMobileInfo] = useState(false);

  useEffect(() => {
    setStory(currentStory);

    if (currentStory){
      setTimeout(async () => {
        await readStory(currentStory.uuid)
      }, 20000);
    }
  }, [currentStory]);

  const handleZoom = (increment: boolean) => {
    setFontSize(prev => {
      const newSize = increment ? prev + 2 : prev - 2;
      return Math.min(Math.max(newSize, 12), 40);
    });
  };

  const handleToggleUppercase = () => {
    setIsUppercase(prev => !prev);
  };

  const handleToggleDarkTheme = () => {
    setIsDarkTheme(prev => !prev);
  };

  const handleGenerateIllustration = async (story: Story) => {
    try {
      setLoadingIllustration(true)
      const illustration = await createIllustration(story.uuid)
      setStory((prevStory: any) => ({
        ...prevStory,
        details: {
          ...prevStory.details,
          illustration_file_url: illustration.data
        }
      }));
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingIllustration(false)
    }
  }

  useEffect(() => {
    localStorage.setItem('story_is_dark_theme', isDarkTheme.toString());
    localStorage.setItem('story_is_uppercase', isUppercase.toString());
    localStorage.setItem('story_is_font_size', fontSize.toString());
  }, [isDarkTheme, isUppercase, fontSize])

  const Sidebar = () => (
    <div className="w-full h-full flex flex-col gap-6">
      {/* Illustration */}
      {story.details.illustration_file_url ? (
        <div className="space-y-4 -mx-2">
          <img 
            src={story.details.illustration_file_url} 
            alt={story.title} 
            className="w-full rounded-lg object-cover"
          />
        </div>
      ) : isAuthenticated && userProfile && userProfile.uuid === story.creator_user_id ? (
        <div className="space-y-4 -mx-2">
            <button
              onClick={() => handleGenerateIllustration(story)}
              disabled={loadingIllustration}
              className="px-6 py-3 bg-gradient-to-r from-purple-500/80 to-blue-500/80 rounded-lg hover:from-purple-500/90 hover:to-blue-500/90 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loadingIllustration ? <Loading size={24} variant="gradient" withText={false}></Loading> : t('ui.generate_illustration')}
            </button>
        </div>
      ):null}

      {/* Résumé */}
      {story.summary && (
        <div className="space-y-4">
          <h2 className="flex items-center gap-2 text-lg font-medium text-white/90">
            {t('story_details.summary')}
          </h2>
          <p className="text-sm text-white/70 leading-relaxed">
            {story.summary}
          </p>
        </div>
      )}

      {/* Héros */}
      {story.details.heroes && story.details.heroes.length > 0 && (
        <div className="border-t border-white/10 pt-4 space-y-4">
          <h2 className="flex items-center gap-2 text-lg font-medium text-white/90">
            <Users size={20} />
            {t('heroes.characters')}
          </h2>
          <div className="space-y-3">
            {story.details.heroes.map((hero: Hero, index: number) => (
              <div 
                key={index} 
                className="p-3 rounded-lg bg-black/20 border border-white/5 flex items-center gap-3"
              >
                {/* Avatar */}
                <div className="w-8 h-8 rounded-full bg-gradient-to-br from-purple-500/30 to-blue-500/30 flex items-center justify-center shrink-0">
                  {hero.avatar_file_url ? (
                    <img
                      src={hero.avatar_file_url}
                      alt={hero.name}
                      className="w-full h-full rounded-full object-cover"
                    />
                  ) : (
                    <span className="text-sm text-white/70">{hero.name[0]}</span>
                  )}
                </div>

                {/* Name */}
                <span className="text-white/90 flex-grow">
                  {hero.name}
                </span>

                {/* Crown for main character */}
                {index === 0 && (
                  <div className="bg-blue-500/20 p-1.5 rounded-full shrink-0">
                    <Crown size={14} className="text-blue-400" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Thème */}
      {story.details.theme && (
        <div className="border-t border-white/10 pt-4 space-y-4">
          <h2 className="flex items-center gap-2 text-lg font-medium text-white/90">
            <BookMarked size={20} />
            {t('story_elements.theme')}
          </h2>
          <div className="flex flex-wrap gap-2">
            {
              <p className="text-sm text-white/70">{t(`options.themes.${story.details.theme}`)}</p>
            }
          </div>
        </div>
      )}

      {/* Valeurs morales */}
      {Array.isArray(story.details.moral_values) && story.details.moral_values.length > 0 && (
        <div className="border-t border-white/10 pt-4 space-y-4">
          <h2 className="flex items-center gap-2 text-lg font-medium text-white/90">
            <Heart size={20} />
            {t('story_elements.moral_values')}
          </h2>
          <div className="flex flex-wrap gap-2">
            {story.details.moral_values.map((value, index) => (
              <span
                key={index}
                className="px-2 py-1 text-xs rounded-full bg-red-500/10 text-red-300"
              >
                {t(`options.moralValues.${value}`)}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* Éléments éducatifs */}
      {Array.isArray(story.details.educational_elements) && story.details.educational_elements.length > 0 && (
        <div className="border-t border-white/10 pt-4 space-y-4">
          <h2 className="flex items-center gap-2 text-lg font-medium text-white/90">
            <GraduationCap size={20} />
            {t('story_elements.educational_elements')}
          </h2>
          <div className="flex flex-wrap gap-2">
            {story.details.educational_elements.map((element, index) => (
              <span
                key={index}
                className="px-2 py-1 text-xs rounded-full bg-yellow-500/10 text-yellow-300"
              >
                {t(`options.educationalElements.${element}`)}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className={`relative min-h-screen overflow-x-hidden ${isDarkTheme ? 'dark' : ''}`}>
      {/* Background */}
      <div 
        className="fixed inset-0 w-full h-full transition-all duration-1000 ease-in-out"
        style={{
          backgroundImage: `url(${story.details.illustration_file_url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          filter: 'brightness(0.5)'
        }}
      />
      <div className={`fixed inset-0 ${isDarkTheme ? 'bg-black/85 via-black/80 to-black/85' : 'bg-gradient-to-b from-black/85 via-black/80 to-black/85'}`} />

      {/* Contenu principal */}
      <div className="relative flex flex-col lg:flex-row min-h-screen">
        {/* Zone de lecture principale */}
        <div className="flex-1 flex flex-col p-6">
          {/* Header avec contrôles */}
          <div className="flex justify-between items-center mb-6 bg-black/30 rounded-xl p-4 backdrop-blur-sm border border-white/10" style={{ backgroundColor: !isDarkTheme ? '#FFF' : '' }}>
            <div>
              <h1 className={`text-2xl font-bold ${isUppercase ? 'uppercase' : ''} ${isDarkTheme ? 'text-white/90' : 'text-black'}`}>{story.title}</h1>
              <div className={`flex items-center gap-4 mt-2 text-sm ${isDarkTheme ? 'text-white/60' : 'text-black/60'}`}>
                {story.details.settings.isInteractive ? (
                  <span className="flex items-center gap-1">
                    <Gamepad size={16} />
                    {t('interactive_story')}
                  </span>
                ) : (
                  <span className="flex items-center gap-1">
                    <ScrollText size={16} />
                    {t('stories.classic_story')}
                  </span>
                )}
                {story.details.settings.duration &&
                  <span className="flex items-center gap-1">
                    <Clock size={16} />
                    {story.details.settings.duration} {t('stories_page.card.minutes')}
                  </span>
                }
                {story.details.settings.ageRange && 
                  <span className="flex items-center gap-1">
                    <Users size={16} />
                    {story.details.settings.ageRange} {t('stories_page.card.years')}
                  </span>
                }
              </div>
            </div>

            <div className="flex items-center gap-2">
              <button
                onClick={handleToggleUppercase}
                className="p-2 rounded-lg hover:bg-white/5 transition-colors"
              >
                <span className={`font-bold ${isDarkTheme ? 'text-white/60' : 'text-black/60'}`}>Aa</span>
              </button>
              <button
                onClick={handleToggleDarkTheme}
                className="p-2 rounded-lg hover:bg-white/5 transition-colors"
              >
                {isDarkTheme ? (
                  <Sun size={20} className={`${isDarkTheme ? 'text-white/60' : 'text-black/60'}`} />
                ) : (
                  <Moon size={20} className={`${isDarkTheme ? 'text-white/60' : 'text-black/60'}`} />
                )}
              </button>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => handleZoom(false)}
                  className="p-2 rounded-lg hover:bg-white/5 transition-colors"
                >
                  <ZoomOut size={20} className={`${isDarkTheme ? 'text-white/60' : 'text-black/60'}`} />
                </button>
                <span className={`text-sm min-w-[3ch] text-center ${isDarkTheme ? 'text-white/60' : 'text-black/60'}`}>
                  {fontSize}
                </span>
                <button
                  onClick={() => handleZoom(true)}
                  className="p-2 rounded-lg hover:bg-white/5 transition-colors"
                >
                  <ZoomIn size={20} className={`${isDarkTheme ? 'text-white/60' : 'text-black/60'}`} />
                </button>
              </div>
            </div>
          </div>

          {/* Zone de contenu scrollable */}
          <div className="flex-1 overflow-y-auto custom-scrollbar">
            <div
              className={`max-w-3xl mx-auto space-y-6 mb-6 ${isDarkTheme ? 'dark-mode' : ''}`}
              style={{ fontSize: `${fontSize}px` }}
            >
            <div className="backdrop-blur-sm rounded-xl p-6 bg-black/25 border border-white/10" style={{ backgroundColor: !isDarkTheme ? '#FFF' : '' }}>
              {story.details.chapters && story.details.chapters.map((chapter: Chapter, index) => (
                <div key={index} className="mb-8 last:mb-0">
                  <h3 className={`text-3xl font-bold ${isDarkTheme ? 'text-white/90' : 'text-black'} mb-4 ${isUppercase ? 'uppercase' : ''}`}>
                    {chapter.title}
                  </h3>
                  <p className={`leading-relaxed ${isDarkTheme ? 'text-white/90' : 'text-black'} whitespace-pre-line ${isUppercase ? 'uppercase' : ''}`}>
                    {chapter.content}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1">
            <button
              disabled={true}
              className={`px-6 py-3 bg-gradient-to-r from-purple-500/80 to-blue-500/80 rounded-lg hover:from-purple-500/90 hover:to-blue-500/90 transition-all disabled:opacity-50 disabled:cursor-not-allowed ${isDarkTheme ? 'text-white/80 hover:text-white' : 'text-black/80 hover:text-black'}`}
            >
              {t('ui.continue_story')} <i>{t('general.coming_soon')}</i>
            </button>
          </div>
        </div>
      </div>

      {/* Sidebar desktop */}
      <div className="hidden lg:block w-80 bg-black/25 backdrop-blur-md border-l border-white/10 p-6 overflow-y-auto">
        <Sidebar />
      </div>

      {/* Bouton toggle pour mobile */}
      <button
        onClick={() => setShowMobileInfo(!showMobileInfo)}
        className="lg:hidden sticky bottom-0 left-0 right-0 w-full py-3 px-6 bg-black/90 backdrop-blur-md border-t border-white/10
                    flex items-center justify-center gap-2 text-white/80 hover:text-white/90 transition-colors"
      >
        {showMobileInfo ? (
          <>
            {t('hide_informations')}
            <ChevronDown size={20} />
          </>
        ) : (
          <>
            {t('show_informations')}
            <ChevronUp size={20} />
          </>
        )}
      </button>

      {/* Sidebar mobile */}
      <div className={`
        lg:hidden w-full bg-black/95 backdrop-blur-md border-t border-white/10 p-6
        transition-all duration-300 ease-in-out
        ${showMobileInfo ? 'max-h-[800px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}
      `}>
        <Sidebar />
      </div>
    </div>
  </div>
  );
};

export default StoryReader;