// pages/StoryPage.tsx

import { useNavigate, useParams } from 'react-router-dom';
import { AppLayout } from '../components/AppLayout/AppLayout';
import GameInterface from '../components/Story/GameInterface'
import StoryReader from '../components/Story/StoryReader';
import { useEffect, useState } from 'react';
import { getStory } from '../api/stories';
import { Story } from '../types/Story';
import { fromApi } from '../utils/parsedStory';
import { Loading } from '../components/Loading/Loading';
import { TextLoading } from '../components/Loading/TextLoading';
import { useTranslation } from 'react-i18next';
import { createClient } from '@supabase/supabase-js';
import { Chapter } from '../types/Chapter';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL!,
  process.env.REACT_APP_SUPABASE_ANON_KEY!
);

export default function StoryPage() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [story, setStory] = useState<Story | null>(null);
  const [loading, setLoadingStory] = useState<boolean>(false);
  const [generationStatus, setGenerationStatus] = useState<string>('');

  useEffect(() => {
    const fetchStoryDetails = async () => {
      try {
        setLoadingStory(true);
        const fetchedStory: any = await getStory(uuid!);
        const parsedStory: Story = fromApi(fetchedStory);
        if (parsedStory) {
          setStory(parsedStory);
          setGenerationStatus(parsedStory.status);
        }
      } catch (error) {
        console.error('Erreur lors du chargement de l\'histoire:', error);
      } finally {
        setLoadingStory(false);
      }
    };
    
    fetchStoryDetails();

    // Subscribe to story changes
    const storySubscription = supabase
      .channel('stories')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'stories', filter: `uuid=eq.${uuid}` },
        async (payload: any) => {
          const fetchedStory: any = await getStory(uuid!);
          const parsedStory: Story = fromApi(fetchedStory);

          setStory((prevStory: Story | null) => {
            if (!prevStory) return null;
  
            switch (payload.eventType) {
              case 'DELETE':
                navigate('/stories');
              break;
              default:
              break;
            }
            return { ...parsedStory };
          });
          
          setGenerationStatus(payload.new.status);
        }
      )
      .subscribe();

    // Subscribe to chapter changes
    const chaptersSubscription = supabase
    .channel('chapters')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'chapters', filter: `story_uuid=eq.${uuid}` },
      (payload: any) => {
        const updatedChapter = payload.new;
        setStory((prevStory: Story | null) => {
          if (!prevStory) return null;

          let updatedChapters: Chapter[] = [...prevStory.details?.chapters || []];
  
          switch (payload.eventType) {
            case 'INSERT':
              updatedChapters = [...updatedChapters, updatedChapter];
            break;
            case 'UPDATE':
              updatedChapters = updatedChapters.map((chapter: Chapter) =>
                chapter.uuid === updatedChapter.uuid ? updatedChapter : chapter
              );
            break;
            case 'DELETE':
              updatedChapters = updatedChapters.filter((chapter) => chapter.uuid !== updatedChapter.uuid);
            break;
            default:
            break;
          }
          
          const prevDetails = prevStory.details;
          return { ...prevStory, details: { ...prevDetails, chapters: updatedChapters } };
        });
      }
    ).subscribe();

    return () => {
      storySubscription.unsubscribe();
      chaptersSubscription.unsubscribe();
    };
  }, [uuid]);

  const renderLoadingState = () => {
    switch (generationStatus) {
      case 'pending':
        return "Préparation de votre histoire...";
      case 'generating':
        return "Création de l'histoire en cours...";
      default:
        return "Chargement...";
    }
  };

  return (
    <AppLayout>
      {(loading || generationStatus === 'pending') && (
        <div className="fixed inset-0 bg-black text-white flex items-center justify-center">
          <div className="fixed inset-0 opacity-30">
            <div className="absolute top-0 left-0 w-[800px] h-[800px] rounded-full bg-purple-500/20 blur-[150px] mix-blend-screen" />
            <div className="absolute bottom-0 right-0 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[150px] mix-blend-screen" />
          </div>
          
          <div className="relative z-10 text-center">
            <Loading size={500} withText={false} />
            <TextLoading 
              text={renderLoadingState()}
              variant="typewriter"
              className="text-2xl mt-8"
            />
          </div>
        </div>
      )}
      
      <div className="min-h-screen bg-neutral-900">
        {story && story.status !== 'pending' && (
          story.details.settings.isInteractive ?
            <GameInterface story={story} gameIsStarted={true} /> :
            <StoryReader currentStory={story} />
        )}
      </div>
    </AppLayout>
  );
}