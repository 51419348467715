import React, { useEffect, useState } from 'react';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { getUserHeroes } from '../api/heroes';
import { HeroCard } from '../components/HeroCard/HeroCard';
import { TextLoading } from '../components/Loading/TextLoading';
import { StoryDetails } from '../types/StoryDetails';
import { StorySettings } from '../types/StorySettings';
import { Hero } from '../types/Hero';
import { StorySettingsStep } from '../components/Story/StorySettingsStep';
import { StorySummary } from '../components/Story/StorySummary';
import { educationalElementsOptions, moralValuesOptions } from '../utils/variables';
import { useTranslation } from 'react-i18next';
import { createChapters, createIllustration, createOldStory, createTitleAndSummary } from '../api/stories';
import { useNavigate } from 'react-router-dom';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Info } from 'lucide-react';
import { useAuth } from '../components/AuthContext/AuthContext';
import { StoryTypeStep } from '../components/Story/StoryTypeStep';

const lsTempStoryFormDataName = 'story-original-form-data';

export const CreateStoryPage: React.FC = () => {
    const { isAuthenticated } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [heroes, setHeroes] = useState<Hero[]>([]);
    const [selectedHeroes, setSelectedHeroes] = useState<Hero[]>([]);
    const [selectedEducationalElements, setSelectedEducationalElements] = useState<string[]>([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [selectedTone, setSelectedTone] = useState<string>('');
    const [selectedTheme, setSelectedTheme] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [storyType, setStoryType] = useState('classic');
    const [additionalContext, setAdditionalContext] = useState('');
    const [createButtonLoading, setCreateButtonLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [storySettings, setStorySettings] = useState<StorySettings>({
        isInteractive: true,
        duration: 2,
        isPublic: false,
        difficulty: 'medium',
        ageRange: '7-9',
        language: 'fr'
    });

    const [storyData, setStoryData] = useState<StoryDetails>({
        context: '',
        duration: 2,
        heroes: [],
        type: '',
        settings: storySettings,
        illustration_file_url: 'assets/background2.webp'
    });

    const heroUuidFromUrl = new URLSearchParams(location.search).get('hero_uuid');
    const storyTypeFromUrl = new URLSearchParams(location.search).get('type');

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login')
        }
    });

    useEffect(() => {
        if (storyTypeFromUrl && (storyTypeFromUrl === 'classic' || storyTypeFromUrl === 'interactive')) {
            setStoryType(storyTypeFromUrl)
        }
    }, [storyTypeFromUrl]);

    useEffect(() => {
        setStorySettings(prevSettings => ({
            ...prevSettings,
            isInteractive: storyType === 'classic' ? false : true
        }))
    }, [storyType]);

    useEffect(() => {
        async function fetchHeroes() {
            setLoading(true);
            try {
                const fetchedHeroes = await getUserHeroes();
                setHeroes(fetchedHeroes);
    
                if (heroUuidFromUrl) {
                    const preselectedHero = fetchedHeroes.find(
                        (hero: Hero) => hero.uuid === heroUuidFromUrl
                    );
                    if (preselectedHero) {
                        const heroWithMain = { ...preselectedHero, isMainCharacter: true };
                        setSelectedHeroes([heroWithMain]);
                        setStoryData(prevData => ({
                            ...prevData,
                            heroes: [heroWithMain]
                        }));
                    }
                }
            } catch (error) {
                console.error('Error fetching heroes:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchHeroes();

        // Restore saved data from localStorage
        const savedData = JSON.parse(localStorage.getItem(lsTempStoryFormDataName) || '{}');
        if (savedData.step) {
            setStep(savedData.step);
            setSelectedHeroes(savedData.selectedHeroes || []);
            setSelectedEducationalElements(savedData.selectedEducationalElements || []);
            setSelectedValues(savedData.selectedValues || []);
            setSelectedTone(savedData.selectedTone || '');
            setSelectedTheme(savedData.selectedTheme || '');
            setStoryType(savedData.storyType || 'classic');
            setStorySettings(savedData.storySettings || {
                isInteractive: true,
                duration: 2,
                isPublic: false,
                difficulty: 'medium',
                ageRange: '7-9',
                language: 'fr'
            });
            setAdditionalContext(savedData.additionalContext || '');
        } else {
            // Reset localStorage data if the user is not on the first step
            localStorage.removeItem(lsTempStoryFormDataName);
        }
    }, [heroUuidFromUrl]);

    useEffect(() => {
        const currentState = {
            selectedHeroes,
            selectedEducationalElements,
            selectedValues,
            selectedTone,
            selectedTheme,
            storyType,
            storySettings,
            additionalContext,
            step
        };
        localStorage.setItem(lsTempStoryFormDataName, JSON.stringify(currentState));
    }, [selectedHeroes, selectedEducationalElements, selectedValues, selectedTone, selectedTheme, storyType, storySettings, additionalContext, step]);

    const handleHeroSelection = (hero: Hero) => {
        setSelectedHeroes(prev => {
            const isAlreadySelected = prev.some(h => h.uuid === hero.uuid);
            if (isAlreadySelected) {
                return prev.filter(h => h.uuid !== hero.uuid);
            } else {
                const isFirstHero = prev.length === 0;
                const heroToAdd = { ...hero, isMainCharacter: isFirstHero };
                return [...prev, heroToAdd];
            }
        });
    };

    const handleCreateStory = async () => {
        try {
            setCreateButtonLoading(true);
            
            const storyData = {
                type: storyType,
                tone: selectedTone,
                theme: selectedTheme,
                prompt: additionalContext,
                educational_elements: selectedEducationalElements,
                moral_values: selectedValues,
                heroes: selectedHeroes,
                settings: storySettings,
                language: storySettings.language,
                duration: storySettings.duration,
            }
            const createStoryResponse = await createOldStory(storyData);
            const storyUUID = createStoryResponse.story_uuid;

            localStorage.removeItem(lsTempStoryFormDataName);
            navigate(`/story/${storyUUID}`);

            if (!storyData.settings.isInteractive) {
                await createChapters(storyUUID, storyData);
                await Promise.all([
                    createTitleAndSummary(storyUUID),
                    createIllustration(storyUUID),
                ]);
            }
            
        } catch (error) {
            setErrorMessage('error');
        } finally {
            setCreateButtonLoading(false);
        }
    };

    const handleSetMainHero = (hero: Hero) => {
        setSelectedHeroes(prev => 
            prev.map(h => ({
                ...h,
                isMainCharacter: h.uuid === hero.uuid
            }))
        );
    };

    return (
        <AppLayout>
            <div className="flex-1 min-h-screen bg-black text-white">
                <div className="fixed inset-0 opacity-30">
                    <div className="absolute top-0 right-0 w-[600px] h-[600px] rounded-full bg-purple-500/20 blur-[150px] mix-blend-screen" />
                    <div className="absolute bottom-0 left-0 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[150px] mix-blend-screen" />
                </div>

                <div className="relative flex min-h-screen">
                    <div className="flex-1 lg:mr-80">
                        <div className="max-w-4xl mx-auto px-4 py-8">
                            <div className="flex justify-between items-center mb-12">
                                <h1 className="text-4xl font-bold">{t('create_story.title')}</h1>
                                <div className="text-white/60">
                                    {t('create_story.step')} {step}/4
                                </div>
                            </div>

                            <div className="space-y-8 backdrop-blur-sm bg-black/30 p-8 rounded-xl border border-white/10">
                                {step === 1 && (
                                    <section className="relative">
                                        <div className="max-w-7xl mx-auto px-6">
                                            <div className="grid lg:grid-cols-2 gap-8">
                                                {/* Classic Story Card */}
                                                <div
                                                    className={`relative group ${
                                                        storyType === 'classic'
                                                            ? 'bg-gradient-to-r from-blue-500/40 to-teal-500/40'
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`absolute inset-0 rounded-xl blur-sm opacity-70 transition-all group-hover:opacity-100 ${
                                                            storyType === 'classic'
                                                                ? 'bg-gradient-to-r from-blue-500/40 to-teal-500/40'
                                                                : ''
                                                        }`}
                                                    />
                                                    <div
                                                        className={`relative backdrop-blur-sm p-8 rounded-xl border transition-all h-full ${
                                                            storyType === 'classic'
                                                                ? 'bg-white/10 border-white/20 hover:border-white/30'
                                                                : 'bg-white/5 border-white/10 hover:border-white/20'
                                                        }`}
                                                    >
                                                        <div className="relative h-48 mb-6 rounded-lg overflow-hidden">
                                                            <div
                                                                className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-teal-500/10 mix-blend-overlay"
                                                            />
                                                            <img
                                                                src="assets/classic-story.webp"
                                                                alt={t('home.story_types.classic.title')}
                                                                className="w-full h-full object-cover"
                                                            />
                                                        </div>
                                                        <h3
                                                            className={`text-2xl font-bold mb-4 bg-clip-text transition-colors ${
                                                                storyType === 'classic'
                                                                    ? 'text-transparent bg-gradient-to-r from-blue-400 to-teal-400'
                                                                    : 'text-white/70'
                                                            }`}
                                                        >
                                                            {t('home.story_types.classic.title')}
                                                        </h3>
                                                        <p
                                                            className={`mb-6 transition-colors ${
                                                                storyType === 'classic'
                                                                    ? 'text-white'
                                                                    : 'text-white/70'
                                                            }`}
                                                        >
                                                            {t('home.story_types.classic.description')}
                                                        </p>
                                                        <button
                                                            onClick={() => {
                                                                setStoryType('classic');
                                                            }}
                                                            className={`w-full px-6 py-3 rounded-lg border transition-all group-hover:scale-105 ${
                                                                storyType === 'classic'
                                                                    ? 'bg-gradient-to-r from-blue-500/40 to-teal-500/40 border-blue-500/50 hover:from-blue-500/50 hover:to-teal-500/50'
                                                                    : 'bg-gradient-to-r from-blue-500/20 to-teal-500/20 border-blue-500/30 hover:bg-blue-500/30'
                                                            }`}
                                                        >
                                                            {t('general.select')}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Interactive Story Card */}
                                                <div
                                                    className={`relative group ${
                                                        storyType === 'interactive'
                                                            ? 'bg-gradient-to-r from-purple-500/40 to-pink-500/40'
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`absolute inset-0 rounded-xl blur-sm opacity-70 transition-all group-hover:opacity-100 ${
                                                            storyType === 'interactive'
                                                                ? 'bg-gradient-to-r from-purple-500/40 to-pink-500/40'
                                                                : ''
                                                        }`}
                                                    />
                                                    <div
                                                        className={`relative backdrop-blur-sm p-8 rounded-xl border transition-all h-full ${
                                                            storyType === 'interactive'
                                                                ? 'bg-white/10 border-white/20 hover:border-white/30'
                                                                : 'bg-white/5 border-white/10 hover:border-white/20'
                                                        }`}
                                                    >
                                                        <div className="relative h-48 mb-6 rounded-lg overflow-hidden">
                                                            <div
                                                                className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-pink-500/10 mix-blend-overlay"
                                                            />
                                                            <img
                                                                src="assets/interactive-story.webp"
                                                                alt={t('home.story_types.interactive.title')}
                                                                className="w-full h-full object-cover"
                                                            />
                                                        </div>
                                                        <h3
                                                            className={`text-2xl font-bold mb-4 bg-clip-text transition-colors ${
                                                                storyType === 'interactive'
                                                                    ? 'text-transparent bg-gradient-to-r from-purple-400 to-pink-400'
                                                                    : 'text-white/70'
                                                            }`}
                                                        ></h3>
                                                        <div>
                                                            <p className={`mb-6 transition-colors ${
                                                                storyType === 'interactive'
                                                                    ? 'text-white'
                                                                    : 'text-white/70'
                                                            }`}>
                                                                {t('home.story_types.interactive.description')}
                                                            </p>
                                                            <i>Bientôt disponible</i>
                                                            {/* <button
                                                                disabled={true}
                                                                onClick={() => {
                                                                    setStoryType('interactive');
                                                                }}
                                                                className={`w-full px-6 py-3 rounded-lg border transition-all group-hover:scale-105 ${
                                                                    storyType === 'interactive'
                                                                        ? 'bg-gradient-to-r from-purple-500/40 to-pink-500/40 border-purple-500/50 hover:from-purple-500/50 hover:to-pink-500/50'
                                                                        : 'bg-gradient-to-r from-purple-500/20 to-pink-500/20 border-purple-500/30 hover:bg-purple-500/30'
                                                                }`}
                                                            >
                                                                {t('general.select')}
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}

                                {step === 2 && (
                                    <div>
                                        <h2 className="text-2xl font-semibold mb-6">
                                            {loading ? <TextLoading text={t('create_story.loading_heroes')}></TextLoading> : t('create_story.choose_heroes')}
                                        </h2>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            {heroes?.map((hero: Hero) => (
                                                <HeroCard 
                                                    key={hero.uuid}
                                                    hero={hero}
                                                    handleClick={() => handleHeroSelection(hero)}
                                                    handleDoubleClick={() => {
                                                        if (selectedHeroes.some(h => h.uuid === hero.uuid)) {
                                                            handleSetMainHero(hero);
                                                        }
                                                    }}
                                                    isSelected={selectedHeroes.some(h => h.uuid === hero.uuid)}
                                                    isMainCharacter={selectedHeroes.some(h => h.uuid === hero.uuid && h.isMainCharacter)}
                                                    withStartButton={false}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {step === 3 && (
                                    <div>
                                        <h2 className="text-2xl font-semibold mb-6">
                                            {t('create_story.educational_values_title')}
                                        </h2>
                                        <div className="space-y-6">
                                            <div>
                                                <label className="block text-lg mb-2">
                                                    {t('create_story.educational_themes')}
                                                </label>
                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                                    {educationalElementsOptions(t).map((theme) => (
                                                        <Tooltip.Provider key={theme.value}>
                                                        <Tooltip.Root>
                                                            <div className="relative">
                                                            <button
                                                                onClick={() => setSelectedEducationalElements(prev => 
                                                                prev.includes(theme.value) 
                                                                    ? prev.filter(t => t !== theme.value)
                                                                    : [...prev, theme.value]
                                                                )}
                                                                className={`
                                                                p-2 pr-8 rounded border transition-all w-full text-left
                                                                ${selectedEducationalElements.includes(theme.value)
                                                                    ? 'bg-purple-500/20 border-purple-500/50 text-white'
                                                                    : 'bg-white/5 hover:bg-white/10 border-white/10'}
                                                                `}
                                                            >
                                                                {theme.label}
                                                            </button>
                                                            <Tooltip.Trigger asChild>
                                                                <button
                                                                type="button"
                                                                className="absolute right-2 top-1/2 -translate-y-1/2 p-1 hover:bg-white/10 rounded-full"
                                                                >
                                                                <Info size={14} className="text-white/60" />
                                                                </button>
                                                            </Tooltip.Trigger>
                                                            </div>
                                                            <Tooltip.Portal>
                                                            <Tooltip.Content
                                                                className="bg-black/90 backdrop-blur-sm text-white p-3 rounded-lg text-sm max-w-xs border border-white/10"
                                                                side="right"
                                                                sideOffset={5}
                                                            >
                                                                {t(`options.educationalElements.descriptions.${theme.value}`)}
                                                                <Tooltip.Arrow className="fill-black/90" />
                                                            </Tooltip.Content>
                                                            </Tooltip.Portal>
                                                        </Tooltip.Root>
                                                        </Tooltip.Provider>
                                                    ))}
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-lg mb-2">
                                                    {t('create_story.moral_values')}
                                                </label>
                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                                    {moralValuesOptions(t).map((moral) => (
                                                        <Tooltip.Provider key={moral.value}>
                                                            <Tooltip.Root>
                                                                <div className="relative">
                                                                    <button
                                                                        onClick={() => setSelectedValues(prev => 
                                                                            prev.includes(moral.value) 
                                                                                ? prev.filter(v => v !== moral.value)
                                                                                : [...prev, moral.value]
                                                                        )}
                                                                        className={`
                                                                            p-2 pr-8 rounded border transition-all w-full text-left
                                                                            ${selectedValues.includes(moral.value)
                                                                                ? 'bg-purple-500/20 border-purple-500/50 text-white'
                                                                                : 'bg-white/5 hover:bg-white/10 border-white/10'}
                                                                        `}
                                                                    >
                                                                        {moral.label}
                                                                    </button>
                                                                    <Tooltip.Trigger asChild>
                                                                        <button
                                                                            type="button"
                                                                            className="absolute right-2 top-1/2 -translate-y-1/2 p-1 hover:bg-white/10 rounded-full"
                                                                        >
                                                                            <Info size={14} className="text-white/60" />
                                                                        </button>
                                                                    </Tooltip.Trigger>
                                                                </div>
                                                                <Tooltip.Portal>
                                                                    <Tooltip.Content
                                                                        className="bg-black/90 backdrop-blur-sm text-white p-3 rounded-lg text-sm max-w-xs border border-white/10"
                                                                        side="right"
                                                                        sideOffset={5}
                                                                    >
                                                                        {t(`options.moralValues.descriptions.${moral.value}`)}
                                                                        <Tooltip.Arrow className="fill-black/90" />
                                                                    </Tooltip.Content>
                                                                </Tooltip.Portal>
                                                            </Tooltip.Root>
                                                        </Tooltip.Provider>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {step === 4 && (
                                    <>
                                        <StorySettingsStep
                                            settings={storySettings}
                                            onSettingsChange={setStorySettings}
                                        />
                                        <StoryTypeStep
                                            selectedTheme={selectedTheme}
                                            selectedTone={selectedTone}
                                            onToneChange={setSelectedTone}
                                            onThemeChange={setSelectedTheme}
                                            additionalContext={additionalContext}
                                            onAdditionalContextChange={setAdditionalContext}
                                        />
                                    </>
                                )}

                                <div className="flex justify-between mt-8">
                                    {step > 1 && (
                                        <button
                                            onClick={() => {
                                                const savedData = JSON.parse(localStorage.getItem(lsTempStoryFormDataName) || '{}');
                                                if (savedData.step && savedData.step === 1) {
                                                    setStep(1);
                                                    setSelectedHeroes(savedData.selectedHeroes || []);
                                                    setSelectedEducationalElements(savedData.selectedEducationalElements || []);
                                                    setSelectedValues(savedData.selectedValues || []);
                                                    setSelectedTone(savedData.selectedTone || '');
                                                    setSelectedTheme(savedData.selectedTheme || '');
                                                    setStoryType(savedData.storyType || 'classic');
                                                    setStorySettings(savedData.storySettings || {
                                                        isInteractive: true,
                                                        duration: 2,
                                                        isPublic: false,
                                                        difficulty: 'medium',
                                                        ageRange: '7-9',
                                                        language: 'fr'
                                                    });
                                                    setAdditionalContext(savedData.additionalContext || '');
                                                } else {
                                                    setStep(step - 1);
                                                }
                                            }}
                                            className="px-6 py-3 bg-white/5 rounded-lg hover:bg-white/10 transition-all"
                                        >
                                            {t('create_story.back')}
                                        </button>
                                    )}
                                    
                                    {step < 4 ? (
                                        <button
                                            onClick={() => setStep(step + 1)}
                                            disabled={
                                                (step === 2 && selectedHeroes.length === 0) ||
                                                (step === 3 && (selectedEducationalElements.length === 0 || selectedValues.length === 0))
                                            }
                                            className="px-6 py-3 bg-white/10 rounded-lg hover:bg-white/20 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            {t('create_story.continue')}
                                        </button>
                                    ) : (
                                        <button
                                            onClick={handleCreateStory}
                                            disabled={!storyType || createButtonLoading}
                                            className="px-6 py-3 bg-gradient-to-r from-purple-500/80 to-blue-500/80 rounded-lg hover:from-purple-500/90 hover:to-blue-500/90 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                                {createButtonLoading ? <TextLoading text={t('general.saving')}></TextLoading> : t('create_story.create')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <StorySummary
                    selectedHeroes={selectedHeroes}
                    selectedEducationalElements={selectedEducationalElements}
                    selectedValues={selectedValues}
                    selectedTone={selectedTone}
                    selectedTheme={selectedTheme}
                    storyType={storyType}
                    currentStep={step}
                    isInteractive={storySettings.isInteractive}
                    settings={storySettings}
                />
            </div>
        </AppLayout>
    );
};