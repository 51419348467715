import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Edit2, Trash2, Crown, Wand2 } from 'lucide-react';
import { Hero } from '../../types/Hero';

type HeroCardProps = {
  hero: Hero;
  withStartButton?: boolean;
  isSelected?: boolean;
  isMainCharacter?: boolean;
  handleStartEdit?: (hero: Hero) => void;
  handleDelete?: (uuid: string) => void;
  handleClick?: (uuid: string) => void;
  handleDoubleClick?: (uuid: string) => void;
  onGenerateAvatar?: (heroId: string) => Promise<void>;
};

export const HeroCard: React.FC<HeroCardProps> = ({
  hero,
  handleStartEdit,
  handleDelete,
  handleClick,
  handleDoubleClick,
  onGenerateAvatar,
  withStartButton = true,
  isSelected = false,
  isMainCharacter = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isGenerating, setIsGenerating] = useState(false);
  const [currentHero, setCurrentHero] = useState(hero);
  
  useEffect(() => {
    setCurrentHero(hero)
  }, [hero]);

  const getBgColor = () => {
    if (isMainCharacter) return 'bg-blue-500/20 border-blue-500/50';
    if (isSelected) return 'bg-purple-500/20 border-purple-500/50';
    return 'bg-black/30 border-white/10 hover:border-purple-500/30';
  };

  const handleGenerateAvatar = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!onGenerateAvatar || isGenerating) return;

    setIsGenerating(true);
    try {
      const newAvatarUrl = await onGenerateAvatar(currentHero.uuid);
      setCurrentHero((prevFormData: any) => ({
        ...prevFormData,
        avatar_file_url: newAvatarUrl
      }));
    } catch (error) {
      console.error('Error generating avatar:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div
      key={currentHero.uuid}
      onClick={() => handleClick?.(currentHero.uuid)}
      onDoubleClick={() => handleDoubleClick?.(currentHero.uuid)}
      className={`
        group relative backdrop-blur-sm rounded-xl border
        transition-all duration-300 cursor-pointer
        ${getBgColor()}
      `}
    >
      <div className="p-6 pb-16">
        <div className="flex items-start gap-4">
          {/* Avatar Section */}
          <div className="relative">
            <div className="w-16 h-16 rounded-full bg-gradient-to-br from-purple-500/30 to-blue-500/30 flex items-center justify-center overflow-hidden">
              {currentHero.avatar_file_url ? (
                <img
                  key={currentHero.avatar_file_url}
                  src={`${currentHero.avatar_file_url}&${new Date().getTime()}`}
                  alt={currentHero.name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="relative w-full h-full flex items-center justify-center">
                  <span className="text-2xl absolute">{currentHero.name[0]}</span>
                  <button
                    onClick={handleGenerateAvatar}
                    disabled={isGenerating}
                    className={`
                      absolute inset-0 flex items-center justify-center
                      bg-black/60 opacity-0 group-hover:opacity-100
                      transition-opacity duration-200
                      ${isGenerating ? 'cursor-not-allowed' : 'hover:bg-black/70'}
                    `}
                    title={t('heroes.generate_ai_avatar')}
                  >
                    <Wand2 
                      size={20} 
                      className={`text-purple-300 ${isGenerating ? 'animate-pulse' : ''}`}
                    />
                  </button>
                </div>
              )}
            </div>
            {isMainCharacter && (
              <div className="absolute -top-2 -right-2 w-6 h-6 bg-blue-500/20 rounded-full flex items-center justify-center">
                <Crown size={14} className="text-blue-400" />
              </div>
            )}
          </div>
          
          {/* Hero Info Section */}
          <div className="flex-1">
            {/* Basic Info */}
            <h3 className="text-xl font-semibold mb-1">{currentHero.name}</h3>
            {currentHero.age && (
              <p className="text-white/60 text-sm">{currentHero.age} ans</p>
            )}
            {currentHero.role && (
              <p className="text-purple-400/80 text-sm mt-1">
                {t(`options.roles.${currentHero.role}`)}
              </p>
            )}
            
            {/* Activities */}
            {currentHero.favorite_activities && currentHero.favorite_activities.length > 0 && (
              <div className="mt-4">
                <div className="flex flex-wrap gap-2">
                  {currentHero.favorite_activities.map((activity, idx) => (
                    <span
                      key={idx}
                      className="text-xs px-2 py-1 rounded-full bg-white/5 text-white/70"
                    >
                      {t(`options.activities.${activity}`)}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {/* Colors */}
            {currentHero.favorite_colors && currentHero.favorite_colors.length > 0 && (
              <div className="mt-4">
                <div className="flex flex-wrap gap-2">
                  {currentHero.favorite_colors.map((color, idx) => (
                    <span
                      key={idx}
                      className="text-xs px-2 py-1 rounded-full bg-white/5 text-white/70"
                    >
                      {t(`options.colors.${color}`)}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Action Buttons */}
        {handleStartEdit && handleDelete && (
          <div className="absolute top-4 right-4 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleStartEdit(hero);
              }}
              className="p-2 rounded-full bg-white/5 hover:bg-white/10 transition-all"
              title={t('heroes.edit_hero')}
            >
              <Edit2 size={16} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(currentHero.uuid);
              }}
              className="p-2 rounded-full bg-white/5 hover:bg-red-500/20 transition-all text-red-400"
              title={t('heroes.delete_hero')}
            >
              <Trash2 size={16} />
            </button>
          </div>
        )}
      </div>

      {/* Start Story Button */}
      {withStartButton && (
        <div className="absolute bottom-0 left-0 right-0 p-4 bg-black/20 rounded-b-xl">
          <button
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/create?hero_uuid=${currentHero.uuid}`);
            }}
            className="w-full flex items-center justify-center gap-2 px-3 py-1.5 rounded-lg bg-purple-500/10 text-purple-300 hover:bg-purple-500/20 transition-all text-sm"
          >
            <span>{t('stories.start_story')}</span>
            <ChevronRight size={16} />
          </button>
        </div>
      )}
    </div>
  );
};