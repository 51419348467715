import React from 'react';
import { SideMenu } from '../SideMenu/SideMenu';
import './AppLayout.css';

interface AppLayoutProps {
    children: React.ReactNode; // Type correct pour les enfants
}
  
export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
    return (
      <div className="flex min-h-screen">
        <SideMenu />
        <main className="flex-1 w-0">
          {children}
        </main>
      </div>
    );
};