import { Option } from "../types/Option";

const languagesOptions = [
  { value: "fr", label: "🇫🇷 Français" },
  { value: "en", label: "🇬🇧 English" },
  { value: "es", label: "🇪🇸 Español" },
  { value: "pt", label: "🇵🇹 Português" },
  { value: "de", label: "🇩🇪 Deutsch" },
  { value: "it", label: "🇮🇹 Italiano" },
  { value: "jp", label: "🇯🇵 日本語" },
  { value: "cn", label: "🇨🇳 中文" },
];

const roleOptions = (t: (key: string) => string): Option[] => [
  { value: "mother", label: t("options.roles.mother") },
  { value: "father", label: t("options.roles.father") },
  { value: "daughter", label: t("options.roles.daughter") },
  { value: "son", label: t("options.roles.son") },
  { value: "animal_dog", label: t("options.roles.animal_dog") },
  { value: "animal_cat", label: t("options.roles.animal_cat") },
  { value: "friend", label: t("options.roles.friend") },
  // Ajoutez plus d'options si nécessaire
];

const activitiesOptions = (t: any) => [
  { value: "reading", label: `📚 ${t("options.activities.reading")}` },
  { value: "swimming", label: `🏊 ${t("options.activities.swimming")}` },
  { value: "hiking", label: `🥾 ${t("options.activities.hiking")}` },
  { value: "cycling", label: `🚴 ${t("options.activities.cycling")}` },
  { value: "running", label: `🏃 ${t("options.activities.running")}` },
  { value: "painting", label: `🎨 ${t("options.activities.painting")}` },
  { value: "drawing", label: `✏️ ${t("options.activities.drawing")}` },
  { value: "writing", label: `📝 ${t("options.activities.writing")}` },
  { value: "photography", label: `📷 ${t("options.activities.photography")}` },
  { value: "gardening", label: `🌱 ${t("options.activities.gardening")}` },
  { value: "cooking", label: `👩‍🍳 ${t("options.activities.cooking")}` },
  { value: "baking", label: `🍞 ${t("options.activities.baking")}` },
  { value: "gaming", label: `🎮 ${t("options.activities.gaming")}` },
  { value: "fishing", label: `🎣 ${t("options.activities.fishing")}` },
  { value: "dancing", label: `💃 ${t("options.activities.dancing")}` },
  { value: "singing", label: `🎤 ${t("options.activities.singing")}` },
  {
    value: "playing_music",
    label: `🎸 ${t("options.activities.playing_music")}`,
  },
  { value: "yoga", label: `🧘 ${t("options.activities.yoga")}` },
  { value: "meditation", label: `🧘‍♂️ ${t("options.activities.meditation")}` },
  { value: "traveling", label: `✈️ ${t("options.activities.traveling")}` },
  {
    value: "volunteering",
    label: `🤝 ${t("options.activities.volunteering")}`,
  },
  { value: "knitting", label: `🧶 ${t("options.activities.knitting")}` },
  { value: "board_games", label: `🎲 ${t("options.activities.board_games")}` },
  { value: "surfing", label: `🏄 ${t("options.activities.surfing")}` },
  { value: "skiing", label: `⛷️ ${t("options.activities.skiing")}` },
  {
    value: "snowboarding",
    label: `🏂 ${t("options.activities.snowboarding")}`,
  },
  {
    value: "horse_riding",
    label: `🏇 ${t("options.activities.horse_riding")}`,
  },
  {
    value: "martial_arts",
    label: `🥋 ${t("options.activities.martial_arts")}`,
  },
  {
    value: "rock_climbing",
    label: `🧗 ${t("options.activities.rock_climbing")}`,
  },
  {
    value: "scuba_diving",
    label: `🤿 ${t("options.activities.scuba_diving")}`,
  },
  {
    value: "bird_watching",
    label: `🦅 ${t("options.activities.bird_watching")}`,
  },
  { value: "astronomy", label: `🔭 ${t("options.activities.astronomy")}` },
  { value: "blogging", label: `💻 ${t("options.activities.blogging")}` },
  { value: "investing", label: `💰 ${t("options.activities.investing")}` },
  { value: "coding", label: `💻 ${t("options.activities.coding")}` },
  { value: "robotics", label: `🤖 ${t("options.activities.robotics")}` },
  { value: "fitness", label: `💪 ${t("options.activities.fitness")}` },
  { value: "bowling", label: `🎳 ${t("options.activities.bowling")}` },
  { value: "skating", label: `⛸️ ${t("options.activities.skating")}` },
  { value: "golf", label: `🏌️ ${t("options.activities.golf")}` },
  { value: "tennis", label: `🎾 ${t("options.activities.tennis")}` },
  { value: "basketball", label: `🏀 ${t("options.activities.basketball")}` },
  { value: "soccer", label: `⚽ ${t("options.activities.soccer")}` },
  { value: "rugby", label: `🏉 ${t("options.activities.rugby")}` },
  {
    value: "puzzle_solving",
    label: `🧩 ${t("options.activities.puzzle_solving")}`,
  },
  { value: "origami", label: `🦢 ${t("options.activities.origami")}` },
  { value: "calligraphy", label: `🖋️ ${t("options.activities.calligraphy")}` },
];

const colorsOptions = (t: any) => [
  { value: "red", label: `🔴 ${t("options.colors.red")}` },
  { value: "blue", label: `🔵 ${t("options.colors.blue")}` },
  { value: "green", label: `🟢 ${t("options.colors.green")}` },
  { value: "yellow", label: `🟡 ${t("options.colors.yellow")}` },
  { value: "purple", label: `🟣 ${t("options.colors.purple")}` },
  { value: "pink", label: `🌸 ${t("options.colors.pink")}` },
  { value: "orange", label: `🟠 ${t("options.colors.orange")}` },
  { value: "brown", label: `🟤 ${t("options.colors.brown")}` },
  { value: "black", label: `⚫ ${t("options.colors.black")}` },
  { value: "white", label: `⚪ ${t("options.colors.white")}` },
  { value: "gray", label: `🔘 ${t("options.colors.gray")}` },
  { value: "cyan", label: `🟦 ${t("options.colors.cyan")}` },
  { value: "magenta", label: `🟪 ${t("options.colors.magenta")}` },
  { value: "gold", label: `🥇 ${t("options.colors.gold")}` },
  { value: "silver", label: `🥈 ${t("options.colors.silver")}` },
  { value: "navy", label: `🟦 ${t("options.colors.navy")}` },
  { value: "lime", label: `🟢 ${t("options.colors.lime")}` },
  { value: "violet", label: `🔮 ${t("options.colors.violet")}` },
  { value: "indigo", label: `🟦 ${t("options.colors.indigo")}` },
  { value: "turquoise", label: `🟦 ${t("options.colors.turquoise")}` },
  { value: "teal", label: `🟩 ${t("options.colors.teal")}` },
];

const moralValuesOptions = (t: any) => [
  { value: "honesty", label: t("options.moralValues.honesty") },
  { value: "respect", label: t("options.moralValues.respect") },
  { value: "kindness", label: t("options.moralValues.kindness") },
  { value: "responsibility", label: t("options.moralValues.responsibility") },
  { value: "fairness", label: t("options.moralValues.fairness") },
  { value: "perseverance", label: t("options.moralValues.perseverance") },
  { value: "courage", label: t("options.moralValues.courage") },
  { value: "empathy", label: t("options.moralValues.empathy") },
  { value: "generosity", label: t("options.moralValues.generosity") },
  { value: "gratitude", label: t("options.moralValues.gratitude") },
  { value: "humility", label: t("options.moralValues.humility") },
  { value: "patience", label: t("options.moralValues.patience") },
  { value: "loyalty", label: t("options.moralValues.loyalty") },
  { value: "self_control", label: t("options.moralValues.self_control") },
  { value: "compassion", label: t("options.moralValues.compassion") },
  { value: "friendship", label: t("options.moralValues.friendship") },
  { value: "justice", label: t("options.moralValues.justice") },
  { value: "wisdom", label: t("options.moralValues.wisdom") },
];

const tonesOptions = (t: any) => [
  { value: "humor", label: t("options.tones.humor") },
  { value: "adventure", label: t("options.tones.adventure") },
  { value: "mystery", label: t("options.tones.mystery") },
  { value: "fantasy", label: t("options.tones.fantasy") },
  { value: "horror", label: t("options.tones.horror") },
  { value: "romantic", label: t("options.tones.romantic") },
  { value: "drama", label: t("options.tones.drama") },
  { value: "thriller", label: t("options.tones.thriller") },
  { value: "inspirational", label: t("options.tones.inspirational") },
  { value: "melancholy", label: t("options.tones.melancholy") },
  { value: "suspense", label: t("options.tones.suspense") },
  { value: "lighthearted", label: t("options.tones.lighthearted") },
];

const themesOptions = (t: any) => [
  {
    value: "fantasy_adventure",
    label: t("options.themes.fantasy_adventure"),
  },
  { value: "time_travel", label: t("options.themes.time_travel") },
  {
    value: "mystery_investigation",
    label: t("options.themes.mystery_investigation"),
  },
  {
    value: "space_exploration",
    label: t("options.themes.space_exploration"),
  },
  {
    value: "historical_fiction",
    label: t("options.themes.historical_fiction"),
  },
  {
    value: "contemporary_drama",
    label: t("options.themes.contemporary_drama"),
  },
  { value: "sci-fi_dystopia", label: t("options.themes.sci-fi_dystopia") },
  {
    value: "mythological_tale",
    label: t("options.themes.mythological_tale"),
  },
  { value: "magical_realism", label: t("options.themes.magical_realism") },
  { value: "coming_of_age", label: t("options.themes.coming_of_age") },
  { value: "romance_drama", label: t("options.themes.romance_drama") },
  {
    value: "post_apocalyptic",
    label: t("options.themes.post_apocalyptic"),
  },
  {
    value: "adventure_survival",
    label: t("options.themes.adventure_survival"),
  },
  {
    value: "steampunk_fantasy",
    label: t("options.themes.steampunk_fantasy"),
  },
  { value: "folktale_legend", label: t("options.themes.folktale_legend") },
  {
    value: "political_thriller",
    label: t("options.themes.political_thriller"),
  },
  { value: "family_drama", label: t("options.themes.family_drama") },
  { value: "science_fiction", label: t("options.themes.science_fiction") },
];

const educationalElementsOptions = (t: any) => [
  { value: "history", label: t("options.educationalElements.history") },
  { value: "science", label: t("options.educationalElements.science") },
  { value: "art", label: t("options.educationalElements.art") },
  { value: "geography", label: t("options.educationalElements.geography") },
  { value: "language", label: t("options.educationalElements.language") },
  { value: "math", label: t("options.educationalElements.math") },
  { value: "technology", label: t("options.educationalElements.technology") },
  {
    value: "engineering",
    label: t("options.educationalElements.engineering"),
  },
  { value: "literature", label: t("options.educationalElements.literature") },
  { value: "philosophy", label: t("options.educationalElements.philosophy") },
  {
    value: "social_studies",
    label: t("options.educationalElements.social_studies"),
  },
  { value: "music", label: t("options.educationalElements.music") },
  { value: "biology", label: t("options.educationalElements.biology") },
  { value: "physics", label: t("options.educationalElements.physics") },
  { value: "chemistry", label: t("options.educationalElements.chemistry") },
  { value: "economics", label: t("options.educationalElements.economics") },
];

function getLanguageLabel(languageCode: string) {
  const language = languagesOptions.find((lang) => lang.value === languageCode);
  return language ? language.label : "Unknown Language";
}

export {
  languagesOptions,
  roleOptions,
  activitiesOptions,
  colorsOptions,
  moralValuesOptions,
  tonesOptions,
  themesOptions,
  educationalElementsOptions,
  getLanguageLabel,
};
