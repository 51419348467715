import { GameState } from "../types/GameState";
import { Message } from "../types/Message";
import { Story } from "../types/Story";
import { StoryProgress } from "../types/StoryProgress";
import { axiosInstanceWithUserAuth, axiosInstance } from "./axios-instance";

export const getAllStories = async () => {
  try {
    const response = await axiosInstance.post(`/stories`, {
      action: "GET_ALL_STORIES",
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching stories: ${error}`);
  }
};

export const getUserStories = async () => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "GET_USER_STORIES",
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching user stories: ${error}`);
  }
};

export const createOldStory = async (storyData: any) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_STORY",
      storyRequest: storyData,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating story: ${error}`);
  }
};

export const createChapters = async (storyUuid: string, storyData: any) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_CHAPTERS",
      storyRequest: storyData,
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating chapters: ${error}`);
  }
};

export const createIllustration = async (storyUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_ILLUSTRATION",
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating illustration: ${error}`);
  }
};

export const createAudio = async (storyUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_AUDIO",
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating audio: ${error}`);
  }
};

export const createTitleAndSummary = async (storyUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_TITLE_AND_SUMMARY",
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating title and summary: ${error}`);
  }
};

export const getStory = async (storyUuid: string) => {
  try {
    const response = await axiosInstance.post(`/stories`, {
      action: "GET_STORY",
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching story by ID: ${error}`);
  }
};

export const updateStory = async (storyUuid: string, data: any) => {
  try {
    const response = await axiosInstance.post(`/stories`, {
      action: "UPDATE_STORY",
      storyUuid,
      data,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching story by ID: ${error}`);
  }
};

export const generateNextScene = async (
  context: string,
  gameState: GameState
): Promise<Message> => {
  try {
    const response = await axiosInstanceWithUserAuth.post(
      `/game/generate-next-scene`,
      {
        context,
        gameState,
      }
    );
    const parsedContent = response.data as Message;

    if (!parsedContent.type || !parsedContent.content) {
      throw new Error("Invalid response format from OpenAI");
    }

    return parsedContent;
  } catch (error) {
    throw new Error(`Error creating illustration: ${error}`);
  }
};

export const loadProgress = async (storyUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(
      `/game/load-progress`,
      {
        storyUuid,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(`Error saving progress: ${error}`);
  }
};

export const saveProgress = async (progress: StoryProgress) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(
      `/game/save-progress`,
      {
        progress,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(`Error saving progress: ${error}`);
  }
};

export const createStory = async (story: any) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/story/create`, {
      story,
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error creating story: ${error}`);
  }
};

export const generateStoryContent = async (story: any) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(
      `/story/generate-content`,
      {
        story,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(`Error generating story content: ${error}`);
  }
};

export const readStory = async (storyUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "STORY_READ",
      storyUuid,
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error generating story content: ${error}`);
  }
};
