import React from 'react';
import { useTranslation } from 'react-i18next';
import { themesOptions, tonesOptions } from '../../utils/variables';

interface StoryTypeStepProps {
  selectedTheme: string;
  selectedTone: string;
  onToneChange: (mood: string) => void;
  onThemeChange: (mood: string) => void;
  additionalContext: string;
  onAdditionalContextChange: (context: string) => void;
}

export const StoryTypeStep: React.FC<StoryTypeStepProps> = ({
  selectedTheme,
  selectedTone,
  onToneChange,
  onThemeChange,
  additionalContext,
  onAdditionalContextChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-8">
      <div>
        <label className="block text-lg text-white/80 mb-2">
          {t('story_elements.theme')}
        </label>
        <div className="space-y-2">
          <select
            className="w-full p-3 rounded-lg bg-white/5 border border-white/10 focus:outline-none focus:border-purple-500/50 text-white/80"
            value={selectedTheme}
            onChange={(e) => onThemeChange(e.target.value)}
          >
            <option value="">{t('ui.select_a_story_theme')}</option>
            {themesOptions(t).map((theme) => (
              <option key={theme.value} value={theme.value}>
                {theme.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label className="block text-lg text-white/80 mb-2">
          {t('story_elements.tone')}
        </label>
        <select
          className="w-full p-3 rounded-lg bg-white/5 border border-white/10 focus:outline-none focus:border-purple-500/50 text-white/80"
          value={selectedTone}
          onChange={(e) => onToneChange(e.target.value)}
        >
          <option value="">{t('ui.select_a_story_tone')}</option>
          {tonesOptions(t).map((tone) => (
            <option key={tone.value} value={tone.value}>
              {tone.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-lg text-white/80 mb-2">
          {t('create_story.write_prompt_idea_label')}
        </label>
        <textarea
          value={additionalContext}
          onChange={(e) => onAdditionalContextChange(e.target.value)}
          className="w-full h-32 p-4 rounded-lg bg-white/5 border border-white/10 focus:outline-none focus:border-purple-500/50 text-white/80"
          placeholder={t('create_story.write_prompt_idea_placeholder')}
        />
      </div>
    </div>
  );
};