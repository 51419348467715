import React from 'react';
import { StoryDetails } from '../../types/StoryDetails';
import { Hero } from '../../types/Hero';
import { SummarySection } from './SummarySection';
import Tooltip from '../Tooltip/Tooltip';
import { Crown } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface StorySummaryProps {
  selectedHeroes: (Hero & { isMainCharacter?: boolean })[];
  selectedEducationalElements: string[];
  selectedValues: string[];
  selectedTone: string;
  selectedTheme: string;
  storyType: string;
  currentStep: number;
  isInteractive: boolean;
  settings?: StoryDetails['settings'];
  className?: string;
}

export const StorySummary: React.FC<StorySummaryProps> = ({
  selectedHeroes,
  selectedEducationalElements,
  selectedValues,
  selectedTone,
  selectedTheme,
  storyType,
  currentStep,
  isInteractive,
  settings,
  className = '',
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`
        lg:w-80 bg-black/30 backdrop-blur-md border-l border-white/10
        lg:fixed lg:right-0 lg:top-0 lg:h-full 
        overflow-y-auto
        transition-all duration-300
        ${className}
      `}
    >
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-6 text-white/90">
          {t('story_summary.title')}
        </h3>

        <div className="space-y-6">
          {/* Type Selection */}
          <SummarySection
            step={1}
            currentStep={currentStep}
            title={t('story_summary.sections.type')}
          >
            <div>
              {storyType && (
                <p className="text-sm text-white/70">{t(`story_summary.story_types.${storyType}`)}</p>
              )}
            </div>
          </SummarySection>

          {/* Heroes */}
          <SummarySection
            step={2}
            currentStep={currentStep}
            title={t('story_summary.sections.heroes')}
          >
            <div className="space-y-2">
              {selectedHeroes.map((hero: Hero) => (
                <Tooltip
                  key={hero.uuid}
                  content={
                    <div className="p-2">
                      {hero.traits && (
                        <div className="text-sm text-white/70">
                          {hero.traits.join(', ')}
                        </div>
                      )}
                    </div>
                  }
                >
                  <label
                    className="flex items-center gap-2 p-2 bg-black/20 rounded-lg cursor-pointer hover:bg-black/30 transition-colors"
                  >
                    <span className="text-white/80 flex-grow">{hero.name}</span>
                    {hero.isMainCharacter && (
                      <span className="px-2 py-1 bg-blue-500/20 rounded-full text-xs shrink-0">
                        <Crown size={14} className="text-blue-400" />
                      </span>
                    )}
                  </label>
                </Tooltip>
              ))}
            </div>
          </SummarySection>

          {/* Educational Elements and Values */}
          <SummarySection
            step={3}
            currentStep={currentStep}
            title={t('story_summary.sections.style')}
          >
            {selectedEducationalElements.length > 0 && (
              <div className="mb-4">
                <h5 className="text-sm text-white/60 mb-2">
                  {t('story_summary.labels.educational_elements')}
                </h5>
                <div className="flex flex-wrap gap-2">
                  {selectedEducationalElements.map((elmt) => (
                    <span
                      key={elmt}
                      className="px-2 py-1 bg-black/20 rounded-full text-xs text-white/70"
                    >
                      {t(`options.educationalElements.${elmt}`)}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {selectedValues.length > 0 && (
              <div>
                <h5 className="text-sm text-white/60 mb-2">
                  {t('story_summary.labels.values')}
                </h5>
                <div className="flex flex-wrap gap-2">
                  {selectedValues.map((value) => (
                    <span
                      key={value}
                      className="px-2 py-1 bg-black/20 rounded-full text-xs text-white/70"
                    >
                      {t(`options.moralValues.${value}`)}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </SummarySection>

          {/* Story Settings */}
          <SummarySection
            step={4}
            currentStep={currentStep}
            title={t('story_summary.sections.settings')}
          >
            {selectedTheme && (
              <div className="mb-4">
                <h5 className="text-sm text-white/60 mb-1">
                  {t('story_summary.labels.theme')}
                </h5>
                <p className="text-sm text-white/70">
                {t(`options.themes.${selectedTheme}`)}
                </p>
              </div>
            )}
            {selectedTone && (
              <div className="mb-4">
                <h5 className="text-sm text-white/60 mb-1">
                  {t('story_summary.labels.tone')}
                </h5>
                <p className="text-sm text-white/70">
                  {t(`options.tones.${selectedTone}`)}
                </p>
              </div>
            )}
            {settings && (
              <div>
                <h5 className="text-sm text-white/60 mb-1">
                  {t('story_summary.labels.settings')}
                </h5>
                <div className="space-y-1">
                  {isInteractive ? (
                    <p className="text-sm text-white/70">
                      {t('story_summary.settings.difficulty')} {t(`stories_page.filters.difficulty_levels.${settings.difficulty}`)}
                    </p>
                  ) : (
                    <>
                      <p className="text-sm text-white/70">
                        {t('story_summary.settings.duration')} {settings.duration}{' '}
                        {t('story_summary.settings.duration_unit')}
                      </p>
                      <p className="text-sm text-white/70">
                        {t('story_summary.settings.visibility')}{' '}
                        {settings.isPublic
                          ? t('story_summary.settings.public')
                          : t('story_summary.settings.private')}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </SummarySection>
        </div>
      </div>

      {/* Progress bar */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-white/10">
        <div
          className="h-full bg-gradient-to-r from-purple-500 to-blue-500 transition-all duration-500"
          style={{ width: `${(currentStep / 4) * 100}%` }}
        />
      </div>
    </div>
  );
};

export default StorySummary;