export const DifficultyBadge: React.FC<{ difficulty?: "easy" | "medium" | "hard" }> = ({ difficulty }) => {
    const colors = {
      easy: "text-green-400 bg-green-500/10",
      medium: "text-yellow-400 bg-yellow-500/10",
      hard: "text-red-400 bg-red-500/10"
    };
    
    const labels = {
      easy: "Facile",
      medium: "Moyen",
      hard: "Difficile"
    };
  
    if (!difficulty) return null;
  
    return (
      <span className={`px-2 py-1 rounded-full text-xs ${colors[difficulty]}`}>
        {labels[difficulty]}
      </span>
    );
  };