import React from 'react';
import '../styles/HomePage.css';
import { useAuth } from '../components/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LanguageSwitcher } from '../components/LanguageSwitcher/LanguageSwitcher';

export const HomePage: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  return (
    <>
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 bg-black/30 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <nav className="flex items-center justify-between">
            <div className="flex items-center gap-8">
              <a href="/" className="text-2xl font-bold text-white hover:text-purple-400 transition-colors">
                {t('home_menu.infinistories')}
              </a>
              
              <div className="hidden md:flex items-center gap-6">
                <a href="/stories" className="text-white/80 hover:text-purple-400 transition-colors">
                  {t('home_menu.stories')}
                </a>
                <a href="/#" className="text-white/80 hover:text-purple-400 transition-colors">
                  {t('home_menu.pricing')}
                </a>
                <a href="/#" className="text-white/80 hover:text-purple-400 transition-colors">
                  {t('home_menu.blog')}
                </a>
                <a href="/#" className="text-white/80 hover:text-purple-400 transition-colors">
                  {t('home_menu.about')}
                </a>
              </div>
            </div>

            {/* Auth buttons */}
            {isAuthenticated ? 
              <div className="flex items-center gap-4">
                <a href="/account" className="px-4 py-2 text-white/80 hover:text-white transition-colors">
                  {t('menu.menu_my_account')}
                </a>
              </div> :
              <div className="flex items-center gap-4">
                <a href="/login" className="px-4 py-2 text-white/80 hover:text-white transition-colors">
                  {t('home_menu.login')}
                </a>
                <a
                  href="/signup"
                  className="px-4 py-2 bg-purple-500/20 border border-purple-500/30 rounded-lg hover:bg-purple-500/30 transition-all text-white"
                >
                  {t('home_menu.signup')}
                </a>
              </div>
            }
          </nav>
        </div>
      </header>

      <main className="pt-16 min-h-screen bg-[#0A0A0F] text-white relative overflow-x-hidden">
        {/* Background gradients */}
        <div className="fixed inset-0 opacity-40">
          <div className="absolute top-0 left-0 w-[800px] h-[800px] rounded-full bg-gradient-to-r from-purple-500/30 to-blue-500/30 blur-[150px] mix-blend-screen" />
          <div className="absolute top-[20%] right-0 w-[600px] h-[600px] rounded-full bg-gradient-to-r from-blue-500/30 to-teal-500/30 blur-[150px] mix-blend-screen" />
          <div className="absolute bottom-0 left-[20%] w-[700px] h-[700px] rounded-full bg-gradient-to-r from-teal-500/30 to-purple-500/30 blur-[150px] mix-blend-screen" />
        </div>

        {/* Hero Section */}
        <section className="relative min-h-screen flex items-center">
          <div className="fixed inset-0 w-full h-full">
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black/80 z-10" />
          </div>

          <div className="relative z-20 max-w-7xl mx-auto px-6 py-32 grid lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <h1 className="text-5xl sm:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white">
                {t('general.infinistories')}
              </h1>
              <p className="text-xl sm:text-2xl text-white/90 mb-12">
                {t('home.hero.tagline')}
              </p>
              <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                <button
                  onClick={() => navigate('/create')}
                  className="px-8 py-4 bg-purple-500/20 backdrop-blur-sm border border-purple-500/30 rounded-lg hover:bg-purple-500/30 transition-all hover:scale-105">
                  {t('home.hero.start_button')}
                </button>
              </div>
            </div>
            <div className="hidden lg:block relative w-full h-[600px] rounded-2xl overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-blue-500/10 mix-blend-overlay" />
              <img 
                src='assets/home-img-0.webp'
                alt={t('home.hero.image_alt')}
                className="w-full h-full object-cover object-center rounded-2xl"
              />
            </div>
          </div>
        </section>

        {/* Story Types Section */}
        <section className="relative py-24">
          <div className="max-w-7xl mx-auto px-6">
            <div className="grid lg:grid-cols-2 gap-8">
              {/* Classic Story Card */}
              <div className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-teal-500/20 rounded-xl blur-sm opacity-70 transition-all group-hover:opacity-100" />
                <div className="relative backdrop-blur-sm bg-white/5 p-8 rounded-xl border border-white/10 h-full hover:border-white/20 transition-all">
                  <div className="relative h-48 mb-6 rounded-lg overflow-hidden">
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-teal-500/10 mix-blend-overlay" />
                    <img 
                      src="assets/classic-story.webp" 
                      alt={t('home.story_types.classic.title')}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-400">
                    {t('home.story_types.classic.title')}
                  </h3>
                  <p className="text-white/70 mb-6">
                    {t('home.story_types.classic.description')}
                  </p>
                  <button 
                    onClick={() => navigate('/create?type=classic')} 
                    className="w-full px-6 py-3 bg-gradient-to-r from-blue-500/20 to-teal-500/20 rounded-lg border border-blue-500/30 hover:bg-blue-500/30 transition-all group-hover:scale-105"
                  >
                    {t('home.story_types.classic.cta')}
                  </button>
                </div>
              </div>

              {/* Interactive Story Card */}
              <div className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-xl blur-sm opacity-70 transition-all group-hover:opacity-100" />
                <div className="relative backdrop-blur-sm bg-white/5 p-8 rounded-xl border border-white/10 h-full hover:border-white/20 transition-all">
                  <div className="relative h-48 mb-6 rounded-lg overflow-hidden">
                    <div className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-pink-500/10 mix-blend-overlay" />
                    <img 
                      src="assets/interactive-story.webp" 
                      alt={t('home.story_types.interactive.title')}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
                    {t('home.story_types.interactive.title')}
                  </h3>
                  <p className="text-white/70 mb-6">
                    {t('home.story_types.interactive.description')}
                  </p>
                  <button 
                    onClick={() => navigate('/create?type=interactive')} 
                    className="w-full px-6 py-3 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-lg border border-purple-500/30 hover:bg-purple-500/30 transition-all group-hover:scale-105"
                  >
                    {t('home.story_types.interactive.cta')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="relative py-24">
          <div className="max-w-7xl mx-auto px-6">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white">
              {t('home.features.title')}
            </h2>
            <p className="text-xl text-white/80 text-center mb-16 max-w-3xl mx-auto">
              {t('home.features.subtitle')}
            </p>
            <div className="grid lg:grid-cols-3 gap-8">
              {[
                {
                  title: t('home.features.cards.personalized.title'),
                  description: t('home.features.cards.personalized.description'),
                  illustrationFileName: "home-img-1.webp"
                },
                {
                  title: t('home.features.cards.learning.title'),
                  description: t('home.features.cards.learning.description'),
                  illustrationFileName: "home-img-2.webp",
                  highlighted: true
                },
                {
                  title: t('home.features.cards.infinite.title'),
                  description: t('home.features.cards.infinite.description'),
                  illustrationFileName: "home-img-3.webp"
                }
              ].map((feature, index) => (
                <div key={index} className="relative group">
                  {feature.highlighted && (
                    <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-blue-500/20 to-teal-500/20 rounded-xl -m-1 transition-all group-hover:scale-105" />
                  )}
                  <div className="backdrop-blur-sm bg-white/5 p-6 rounded-xl border border-white/10 hover:border-white/20 transition-all relative">
                    <div className="relative h-48 mb-6 rounded-lg overflow-hidden">
                      <div className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-blue-500/10 mix-blend-overlay" />
                      <img 
                        src={`assets/${feature.illustrationFileName}`}
                        alt={feature.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h3 className="text-xl font-bold mb-3 text-white/90">{feature.title}</h3>
                    <p className="text-white/70">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* How it works section */}
        <section className="relative py-24 overflow-hidden">
          <div className="absolute right-0 top-0 w-[500px] h-[500px] rounded-full bg-gradient-to-r from-purple-500/20 via-pink-500/20 to-blue-500/20 blur-[120px] mix-blend-screen" />
          <div className="max-w-7xl mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white">
              {t('home.how_it_works.title')}
            </h2>
            <p className="text-xl text-white/80 text-center mb-16 max-w-3xl mx-auto">
              {t('home.how_it_works.subtitle')}
            </p>
            <div className="grid lg:grid-cols-3 gap-12">
              {[
                {
                  step: "1",
                  title: t('home.how_it_works.steps.customize.title'),
                  description: t('home.how_it_works.steps.customize.description')
                },
                {
                  step: "2",
                  title: t('home.how_it_works.steps.choose.title'),
                  description: t('home.how_it_works.steps.choose.description')
                },
                {
                  step: "3",
                  title: t('home.how_it_works.steps.explore.title'),
                  description: t('home.how_it_works.steps.explore.description')
                }
              ].map((item, index) => (
                <div key={index} className="relative group">
                  <div 
                    className="absolute -inset-4 bg-gradient-to-r from-purple-500/20 via-pink-500/20 to-blue-500/20 rounded-xl blur-sm transition-opacity group-hover:opacity-100 opacity-70"
                    style={{ transform: 'rotate(-2deg)' }}
                  />
                  <div className="relative backdrop-blur-sm bg-white/5 p-8 rounded-xl border border-white/10 transition-transform group-hover:scale-[1.02]">
                    <span className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 absolute -top-6 -left-4">
                      {item.step}
                    </span>
                    <h3 className="text-2xl font-bold mb-4 text-white/90">{item.title}</h3>
                    <p className="text-white/70">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
    {/* Prix Section */}
    <section className="relative py-24 overflow-hidden">
          <div className="absolute left-0 bottom-0 w-[600px] h-[600px] rounded-full bg-gradient-to-r from-teal-500/20 via-purple-500/20 to-blue-500/20 blur-[150px] mix-blend-screen" />
          <div className="max-w-7xl mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white">
              {t('home.pricing.title')}
            </h2>
            <p className="text-xl text-white/80 text-center mb-16 max-w-2xl mx-auto">
              {t('home.pricing.subtitle')}
            </p>

            <div className="grid lg:grid-cols-3 gap-8">
              {[
                {
                  name: t('home.pricing.plans.discovery.name'),
                  price: t('home.pricing.plans.discovery.price'),
                  features: [
                    t('home.pricing.plans.discovery.features.stories'),
                    t('home.pricing.plans.discovery.features.personalization'),
                    t('home.pricing.plans.discovery.features.library')
                  ],
                  highlighted: false,
                  gradient: "from-blue-500/20 to-teal-500/20"
                },
                {
                  name: t('home.pricing.plans.family.name'),
                  price: t('home.pricing.plans.family.price'),
                  features: [
                    t('home.pricing.plans.family.features.stories'),
                    t('home.pricing.plans.family.features.personalization'),
                    t('home.pricing.plans.family.features.library'),
                    t('home.pricing.plans.family.features.offline'),
                    t('home.pricing.plans.family.features.profiles')
                  ],
                  highlighted: true,
                  gradient: "from-purple-500/20 via-pink-500/20 to-blue-500/20",
                  badge: t('home.pricing.popular_badge')
                },
                {
                  name: t('home.pricing.plans.premium.name'),
                  price: t('home.pricing.plans.premium.price'),
                  features: [
                    t('home.pricing.plans.premium.features.family'),
                    t('home.pricing.plans.premium.features.custom'),
                    t('home.pricing.plans.premium.features.themes'),
                    t('home.pricing.plans.premium.features.support'),
                    t('home.pricing.plans.premium.features.early')
                  ],
                  highlighted: false,
                  gradient: "from-purple-500/20 to-pink-500/20"
                }
              ].map((plan, index) => (
                <div key={index} className="relative group">
                  <div className={`
                    absolute inset-0 rounded-xl -m-1 transition-all duration-500
                    opacity-0 group-hover:opacity-100 blur-sm
                    bg-gradient-to-r ${plan.gradient}
                  `} />

                  <div className={`
                    relative backdrop-blur-sm p-8 rounded-xl border transition-all duration-300
                    ${plan.highlighted 
                      ? 'bg-white/10 border-purple-500/30 shadow-lg shadow-purple-500/10' 
                      : 'bg-white/5 border-white/10 group-hover:border-white/20'}
                  `}>
                    {plan.badge && (
                      <div className="absolute -top-4 left-1/2 -translate-x-1/2 px-4 py-1 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full text-sm font-medium">
                        {plan.badge}
                      </div>
                    )}

                    <h3 className="text-2xl font-bold mb-2 text-white/90">{plan.name}</h3>
                    <div className="text-3xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/80">
                      {plan.price}
                    </div>

                    <ul className="space-y-4 mb-8">
                      {plan.features.map((feature, featureIndex) => (
                        <li key={featureIndex} className="flex items-center gap-3">
                          <span className="h-1.5 w-1.5 rounded-full bg-gradient-to-r from-purple-500 to-blue-500" />
                          <span className="text-white/80">{feature}</span>
                        </li>
                      ))}
                    </ul>

                    <button className={`
                      w-full py-3 rounded-lg transition-all duration-300 
                      ${plan.highlighted
                        ? 'bg-gradient-to-r from-purple-500 to-blue-500 hover:opacity-90'
                        : 'bg-white/10 hover:bg-white/20 border border-white/10'}
                    `}>
                      {t('home.pricing.choose_plan')}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Témoignages Section */}
        <section className="relative py-24">
          <div className="absolute right-0 bottom-0 w-[500px] h-[500px] rounded-full bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-teal-500/20 blur-[120px] mix-blend-screen" />
          <div className="max-w-7xl mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white">
              {t('home.testimonials.title')}
            </h2>
            <p className="text-xl text-white/80 text-center mb-16 max-w-2xl mx-auto">
              {t('home.testimonials.subtitle')}
            </p>

            <div className="grid lg:grid-cols-2 gap-8">
              {[
                {
                  text: t('home.testimonials.reviews.1.text'),
                  author: t('home.testimonials.reviews.1.author'),
                  role: t('home.testimonials.reviews.1.role'),
                  gradient: "from-purple-500/10 to-blue-500/10"
                },
                {
                  text: t('home.testimonials.reviews.2.text'),
                  author: t('home.testimonials.reviews.2.author'),
                  role: t('home.testimonials.reviews.2.role'),
                  gradient: "from-blue-500/10 to-teal-500/10"
                }
              ].map((testimonial, index) => (
                <div key={index} className="relative group">
                  <div className={`
                    absolute inset-0 rounded-xl transition-all duration-500
                    opacity-0 group-hover:opacity-100 blur-sm
                    bg-gradient-to-r ${testimonial.gradient}
                  `} />

                  <div className="relative backdrop-blur-sm bg-white/5 p-8 rounded-xl border border-white/10 group-hover:border-white/20 transition-all">
                    <div className="absolute -left-3 -top-3 text-4xl text-white/20">"</div>
                    <p className="text-lg mb-6 text-white/90 relative z-10">{testimonial.text}</p>
                    <div className="relative z-10">
                      <div className="font-bold text-white/90">{testimonial.author}</div>
                      <div className="text-white/60">{testimonial.role}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* CTA Final */}
        <section className="relative py-24 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 via-blue-500/10 to-teal-500/10" />
          <div className="relative max-w-4xl mx-auto text-center px-6">
            <h2 className="text-3xl sm:text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white">
              {t('home.cta.title')}
            </h2>
            <p className="text-lg sm:text-xl text-white/80 mb-8">
              {t('home.cta.subtitle')}
            </p>
            <button
              onClick={() => navigate('/create')}
              className="px-8 py-4 bg-gradient-to-r from-purple-500 to-blue-500 backdrop-blur-sm rounded-lg transition-all hover:opacity-90"
            >
              {t('home.cta.button')}
            </button>
          </div>
          </section>
        </main>

        {/* Footer */}
        <footer className="bg-black/50 backdrop-blur-sm border-t border-white/10">
          <div className="max-w-7xl mx-auto px-6 py-12">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {/* Company */}
              <div>
                <h4 className="text-lg font-semibold mb-4 text-white">{t('general.infinistories')}</h4>
                <ul className="space-y-2">
                  <li>
                    <a href="/#" className="text-white/60 hover:text-purple-400 transition-colors">
                      {t('home_menu.about')}
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contact@infinistories.com" className="text-white/60 hover:text-purple-400 transition-colors">
                      {t('home_menu.contact')}
                    </a>
                  </li>
                </ul>
              </div>

              {/* Resources */}
              {/* <div>
                <h4 className="text-lg font-semibold mb-4 text-white">{t('home_menu.resources')}</h4>
                <ul className="space-y-2">
                  <li>
                    <a href="/#" className="text-white/60 hover:text-purple-400 transition-colors">
                      {t('home_menu.blog')}
                    </a>
                  </li>
                </ul>
              </div> */}

              {/* Legal */}
              <div>
                <h4 className="text-lg font-semibold mb-4 text-white">{t('home_menu.legal')}</h4>
                <ul className="space-y-2">
                  <li>
                    <a href="/#" className="text-white/60 hover:text-purple-400 transition-colors">
                      {t('home_menu.privacy')}
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="text-white/60 hover:text-purple-400 transition-colors">
                      {t('home_menu.terms')}
                    </a>
                  </li>
                </ul>
              </div>

              {/* Social */}
              <div>
                <h4 className="text-lg font-semibold mb-4 text-white">{t('home_menu.follow_us')}</h4>
                <div className="flex gap-4">
                  {['twitter', 'instagram', 'linkedin'].map((social) => (
                    <a 
                      key={social}
                      href="#" 
                      className="w-10 h-10 rounded-full bg-white/5 flex items-center justify-center hover:bg-purple-500/20 transition-colors"
                    >
                      <i className={`fab fa-${social} text-white/60 hover:text-white`}></i>
                    </a>
                  ))}
                </div>
              </div>
              
              <div>
                <h4 className="text-lg font-semibold mb-4 text-white">{t('home_menu.select_lang')}</h4>
                <div className="flex gap-4">
                  <LanguageSwitcher></LanguageSwitcher>
                </div>
              </div>
            </div>

            <div className="mt-12 pt-8 border-t border-white/10 text-center text-white/40">
              <p>{t('home.footer.copyright')}</p>
            </div>
          </div>
        </footer>
    </>
  );
};

export default HomePage;