import React, { useState, useEffect } from 'react';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { getUserHeroes, createUserHero, updateUserHero, deleteUserHero, generateHeroAvatar } from '../api/heroes';
import { Loading } from '../components/Loading/Loading';
import { Hero } from '../types/Hero';
import { HeroFormModal } from '../components/HeroFormModal/HeroFormModal';
import { HeroCard } from '../components/HeroCard/HeroCard';
import { TextLoading } from '../components/Loading/TextLoading';

export const HeroesPage: React.FC = () => {
  const { t } = useTranslation();
  const [heroes, setHeroes] = useState<Hero[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editingHero, setEditingHero] = useState<Hero | null>(null);
  
  useEffect(() => {
    fetchHeroes();
  }, []);

  const fetchHeroes = async () => {
    setLoading(true);
    const data = await getUserHeroes();
    setHeroes(data);
    setLoading(false);
  };

  const handleStartEdit = (hero: Hero) => {
    setEditingHero(hero);
    setShowModal(true);
  };

  const handleCreate = () => {
    setEditingHero(null);
    setShowModal(true);
  };

  const handleDelete = async (uuid: string) => {
    if (window.confirm(t('heroes.confirm_delete_hero'))) {
      await deleteUserHero(uuid);
      await fetchHeroes();
    }
  };

  const handleGenerateAvatar = async (heroId: string) => {
    try {
      const result = await generateHeroAvatar(heroId);
      setHeroes(prevHeroes => 
        prevHeroes.map(hero => 
          hero.uuid === heroId 
            ? { ...hero, avatar_file_url: result.url }
            : hero
        )
      );

      return result.url;
    } catch (error) {
      console.error('Error generating avatar:', error);
    }
  };

  return (
    <AppLayout>
      <div className="min-h-screen bg-black text-white">
        {/* Background effects */}
        <div className="fixed inset-0 opacity-30">
          <div className="absolute top-0 right-0 w-[600px] h-[600px] rounded-full bg-purple-500/20 blur-[150px] mix-blend-screen" />
          <div className="absolute bottom-0 left-0 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[150px] mix-blend-screen" />
        </div>

        <div className="relative z-10 px-6 py-8">
          {/* Header */}
          <div className="flex justify-between items-center mb-12">
            <h1 className="text-4xl font-bold">{loading ? <TextLoading text={t('heroes.loading_heroes')} variant="typewriter"></TextLoading> : t('heroes.my_heroes')}</h1>
            <button
              onClick={handleCreate}
              className="flex items-center gap-2 px-4 py-2 bg-white/10 rounded-lg border border-white/20 hover:bg-white/20 transition-all"
            >
              <Plus size={20} />
              <span>{t('heroes.create_hero')}</span>
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center items-center min-h-[400px]">
              <Loading size={200} variant="gradient" withText={false} />
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {heroes.map((hero) => (
                <HeroCard
                  key={hero.uuid}
                  hero={hero}
                  handleStartEdit={handleStartEdit}
                  handleDelete={handleDelete}
                  onGenerateAvatar={handleGenerateAvatar}
                />
              ))}
            </div>
          )}
        </div>

        {/* Modal Form */}
        {showModal && (
          <HeroFormModal
            hero={editingHero}
            onClose={() => {
              setShowModal(false);
              setEditingHero(null);
            }}
            onSave={async (hero: Hero, avatar?: File) => {
              if (editingHero) {
                await updateUserHero(hero, avatar);
              } else {
                await createUserHero(hero, avatar);
              }
              await fetchHeroes();
              setShowModal(false);
              setEditingHero(null);
            }}
            onGenerateAvatar={handleGenerateAvatar}
          />
        )}
      </div>
    </AppLayout>
  );
};
