import React, { useState } from 'react';
import { Play, Pause, Search, Sparkles, Filter, Heart, Download, Clock, Globe, 
  CheckCircle, XCircle, FileImage, Grid, List, AlertCircle, Settings, 
  Upload, MoreVertical, Edit3, ShoppingCart, 
  Monitor,
  Tv,
  Radio,
  Users,
  Share2,
  Building} from 'lucide-react';
  interface RequestDetails {
    selectedHitShots: Set<number>;
    usageTypes: Set<UsageType>;
    territory: TerritoryType | null;
    duration: DurationType | null;
    customDuration?: number;
    startDate?: Date;
    endDate?: Date;
  }
  
  interface ValidationStep {
    name: string;
    fields: string[];
    validation: (details: RequestDetails) => { valid: boolean; message?: string };
  }
  
  const ValidationProcess: ValidationStep[] = [
    {
      name: "Sélection",
      fields: ["selectedHitShots"],
      validation: (details) => ({
        valid: details.selectedHitShots.size > 0,
        message: "Veuillez sélectionner au moins un HitShot"
      })
    },
    {
      name: "Usage",
      fields: ["usageTypes"],
      validation: (details) => ({
        valid: details.usageTypes.size > 0,
        message: "Veuillez sélectionner au moins un type d'utilisation"
      })
    },
    {
      name: "Territoire & Durée",
      fields: ["territory", "duration", "startDate", "endDate"],
      validation: (details) => {
        if (!details.territory) return { valid: false, message: "Veuillez sélectionner un territoire" };
        if (!details.duration) return { valid: false, message: "Veuillez sélectionner une durée" };
        if (details.duration === 'CUSTOM' && (!details.startDate || !details.endDate)) {
          return { valid: false, message: "Veuillez spécifier les dates de début et de fin" };
        }
        return { valid: true };
      }
    }
  ];
  
  const UsageSelector: React.FC<{
    selectedUsages: Set<UsageType>;
    onUsageChange: (usages: Set<UsageType>) => void;
  }> = ({ selectedUsages, onUsageChange }) => {
    const toggleUsage = (usage: UsageType) => {
      const newSelection = new Set(selectedUsages);
      if (newSelection.has(usage)) {
        newSelection.delete(usage);
      } else {
        newSelection.add(usage);
      }
      onUsageChange(newSelection);
    };
  
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {(Object.entries(USAGE_TYPES) as [UsageType, UsageConfig][]).map(([type, config]) => (
          <button
            key={type}
            onClick={() => toggleUsage(type)}
            className={`p-4 rounded-lg border transition-all ${
              selectedUsages.has(type)
                ? 'border-orange-500 bg-orange-500/10'
                : 'border-gray-800 hover:border-orange-500/50'
            }`}
          >
            <div className="flex items-center gap-3 mb-2">
              <div className="p-2 rounded-lg bg-gray-800">
                <config.icon size={20} className="text-orange-500" />
              </div>
              <h3 className="text-white font-medium">{config.label}</h3>
            </div>
            <p className="text-sm text-gray-400 mb-2">{config.description}</p>
            {config.restrictions && (
              <div className="text-xs text-orange-500">
                {config.restrictions.map((restriction, i) => (
                  <div key={i} className="flex items-center gap-1">
                    <AlertCircle size={12} />
                    <span>{restriction}</span>
                  </div>
                ))}
              </div>
            )}
          </button>
        ))}
      </div>
    );
  };
  
  const PricingCalculator: React.FC<{
    selectedHitShots: Set<number>;
    selectedUsages: Set<UsageType>;
    territory: TerritoryType | null;
    duration: DurationType | null;
  }> = ({ selectedHitShots, selectedUsages, territory, duration }) => {
    const calculatePrice = () => {
      if (!territory || !duration || selectedUsages.size === 0) return null;
  
      let totalPrice = 0;
      const territoryConfig = TERRITORIES[territory];
      const durationConfig = DURATIONS[duration];
  
      selectedUsages.forEach(usage => {
        const usageConfig = USAGE_TYPES[usage];
        totalPrice += usageConfig.basePrice * territoryConfig.multiplier * durationConfig.multiplier;
      });
  
      totalPrice *= selectedHitShots.size;
  
      return {
        basePrice: totalPrice,
        details: {
          territory: territoryConfig,
          duration: durationConfig,
          usages: Array.from(selectedUsages).map(u => USAGE_TYPES[u])
        }
      };
    };
  
    const price = calculatePrice();
  
    return price ? (
      <div className="bg-gray-900 rounded-lg p-6 space-y-4">
        <h3 className="text-lg font-semibold text-white mb-4">Estimation tarifaire</h3>
        
        <div className="space-y-3">
          <div className="flex justify-between text-sm">
            <span className="text-gray-400">Prix de base</span>
            <span className="text-white">{price.basePrice.toLocaleString()}€</span>
          </div>
          
          <div className="flex justify-between text-sm">
            <span className="text-gray-400">Multiplicateur territoire</span>
            <span className="text-white">x{price.details.territory.multiplier}</span>
          </div>
  
          <div className="flex justify-between text-sm">
            <span className="text-gray-400">Multiplicateur durée</span>
            <span className="text-white">x{price.details.duration.multiplier}</span>
          </div>
  
          <div className="flex justify-between text-sm">
            <span className="text-gray-400">Nombre de HitShots</span>
            <span className="text-white">x{selectedHitShots.size}</span>
          </div>
  
          <div className="pt-3 border-t border-gray-800">
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Total estimé</span>
              <span className="text-2xl font-bold text-orange-500">
                {(price.basePrice * selectedHitShots.size).toLocaleString()}€
              </span>
            </div>
          </div>
        </div>
        
        <div className="mt-4 text-xs text-gray-500">
          * Les prix sont donnés à titre indicatif et peuvent varier selon les conditions spécifiques
        </div>
      </div>
    ) : null;
  };
  
  const ContractSummary: React.FC<{
    details: RequestDetails;
    onValidate: () => void;
  }> = ({ details, onValidate }) => (
    <div className="space-y-6">
      <div className="bg-gray-900 rounded-lg p-6">
        <h3 className="text-lg font-semibold text-white mb-4">Récapitulatif de la demande</h3>
        
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <p className="text-gray-400">Types d'utilisation</p>
              <div className="flex flex-wrap gap-2">
                {Array.from(details.usageTypes).map(usage => (
                  <span key={usage} className="px-2 py-1 rounded-full bg-gray-800 text-white text-sm">
                    {USAGE_TYPES[usage].label}
                  </span>
                ))}
              </div>
            </div>
  
            <div className="space-y-2">
              <p className="text-gray-400">Territoire</p>
              <p className="text-white">
                {details.territory ? TERRITORIES[details.territory].label : '-'}
              </p>
            </div>
  
            <div className="space-y-2">
              <p className="text-gray-400">Durée</p>
              <p className="text-white">
                {details.duration ? DURATIONS[details.duration].label : '-'}
              </p>
            </div>
  
            <div className="space-y-2">
              <p className="text-gray-400">HitShots sélectionnés</p>
              <p className="text-white">{details.selectedHitShots.size}</p>
            </div>
          </div>
  
          <div className="pt-4 border-t border-gray-800">
            <button
              onClick={onValidate}
              className="w-full py-3 px-4 bg-gradient-to-r from-orange-500 to-red-600 
                text-white rounded-lg hover:opacity-90 transition-opacity flex items-center justify-center gap-2"
            >
              <Download size={20} />
              Valider et demander les droits
            </button>
          </div>
        </div>
      </div>
    </div>
      );
  // Types étendus
export type StatusKey = 'PENDING' | 'APPROVED' | 'REJECTED' | 'ACTIVE';
export type UsageType = 'DIGITAL' | 'TV' | 'RADIO' | 'EVENT' | 'SOCIAL' | 'OOH';
export type TerritoryType = 'LOCAL' | 'NATIONAL' | 'EUROPE' | 'WORLDWIDE';
export type DurationType = '3_MONTHS' | '6_MONTHS' | '1_YEAR' | '2_YEARS' | 'CUSTOM';

export interface StatusConfig {
  label: string;
  color: string;
}

export interface UsageConfig {
  label: string;
  description: string;
  icon: React.ElementType;
  basePrice: number;
  restrictions?: string[];
}

export interface TerritoryConfig {
  label: string;
  multiplier: number;
  description: string;
}

export interface DurationConfig {
  label: string;
  months: number;
  multiplier: number;
}

export interface PricingTier {
  name: string;
  minDuration: number;
  maxDuration: number;
  basePrice: number;
  territoryMultipliers: Record<TerritoryType, number>;
  usageMultipliers: Record<UsageType, number>;
}

export interface HitShot {
  id: number;
  title: string;
  artist: string;
  gradient: string;
  duration: string;
  status: StatusKey;
  customized: boolean;
  campaignStart?: string;
  campaignEnd?: string;
  pricing?: {
    tier: string;
    basePrice: number;
  };
  rights?: {
    [key in UsageType]?: boolean;
  };
  territories?: TerritoryType[];
}

export interface StatusConfig {
  label: string;
  color: string;
}

export type StatusMap = {
  [K in StatusKey]: StatusConfig;
};

// Configurations
export const STATUS: StatusMap = {
  PENDING: { label: 'En attente', color: 'text-yellow-400 bg-yellow-400/10' },
  APPROVED: { label: 'Approuvé', color: 'text-green-400 bg-green-400/10' },
  REJECTED: { label: 'Refusé', color: 'text-red-400 bg-red-400/10' },
  ACTIVE: { label: 'En cours', color: 'text-blue-400 bg-blue-400/10' }
};

export const USAGE_TYPES: Record<UsageType, UsageConfig> = {
  DIGITAL: {
    label: 'Digital',
    description: 'Sites web, applications et plateformes numériques',
    icon: Monitor,
    basePrice: 1000,
    restrictions: ['Géo-restriction requise']
  },
  TV: {
    label: 'Télévision',
    description: 'Diffusion TV nationale et câble',
    icon: Tv,
    basePrice: 2500,
    restrictions: ['Déclaration SACEM requise']
  },
  RADIO: {
    label: 'Radio',
    description: 'Diffusion radio FM et numérique',
    icon: Radio,
    basePrice: 1500
  },
  EVENT: {
    label: 'Événementiel',
    description: 'Utilisations lors d\'événements physiques',
    icon: Users,
    basePrice: 800
  },
  SOCIAL: {
    label: 'Réseaux Sociaux',
    description: 'Posts et stories sur les réseaux sociaux',
    icon: Share2,
    basePrice: 1200,
    restrictions: ['Mention obligatoire de l\'artiste']
  },
  OOH: {
    label: 'Affichage',
    description: 'Publicité extérieure et affichage',
    icon: Building,
    basePrice: 2000
  }
};

export const TERRITORIES: Record<TerritoryType, TerritoryConfig> = {
  LOCAL: {
    label: 'Local',
    multiplier: 0.5,
    description: 'Utilisation dans une région spécifique'
  },
  NATIONAL: {
    label: 'National',
    multiplier: 1,
    description: 'Utilisation sur tout le territoire français'
  },
  EUROPE: {
    label: 'Europe',
    multiplier: 2,
    description: 'Union Européenne et Royaume-Uni'
  },
  WORLDWIDE: {
    label: 'Mondial',
    multiplier: 3,
    description: 'Utilisation mondiale sans restriction'
  }
};

export const DURATIONS: Record<DurationType, DurationConfig> = {
  '3_MONTHS': {
    label: '3 mois',
    months: 3,
    multiplier: 0.4
  },
  '6_MONTHS': {
    label: '6 mois',
    months: 6,
    multiplier: 0.7
  },
  '1_YEAR': {
    label: '1 an',
    months: 12,
    multiplier: 1
  },
  '2_YEARS': {
    label: '2 ans',
    months: 24,
    multiplier: 1.8
  },
  'CUSTOM': {
    label: 'Personnalisé',
    months: 0,
    multiplier: 1
  }
};
const myHitshots: HitShot[] = [
    {
      id: 1,
      title: "Pan pan sur toi",
      artist: "Roméo Elvis Méchant",
      gradient: "from-purple-600 to-pink-600",
      duration: "0:30",
      status: 'APPROVED', // Une clé de StatusKey, pas un objet StatusConfig
      customized: true,
      campaignStart: "2024-01-01",
      campaignEnd: "2024-12-31"
    },
    {
      id: 2,
      title: "L'assurance qui vous ressemble",
      artist: "AXA",
      gradient: "from-blue-600 to-cyan-500",
      duration: "0:12",
      status: 'PENDING',
      customized: false
    }
  ];

export const PRICING_TIERS: PricingTier[] = [
  {
    name: 'Standard',
    minDuration: 1,
    maxDuration: 12,
    basePrice: 1000,
    territoryMultipliers: {
      LOCAL: 0.5,
      NATIONAL: 1,
      EUROPE: 2,
      WORLDWIDE: 3
    },
    usageMultipliers: {
      DIGITAL: 1,
      TV: 2.5,
      RADIO: 1.5,
      EVENT: 0.8,
      SOCIAL: 1.2,
      OOH: 2
    }
  },
  {
    name: 'Premium',
    minDuration: 1,
    maxDuration: 24,
    basePrice: 2000,
    territoryMultipliers: {
      LOCAL: 0.6,
      NATIONAL: 1.2,
      EUROPE: 2.4,
      WORLDWIDE: 3.6
    },
    usageMultipliers: {
      DIGITAL: 1.2,
      TV: 3,
      RADIO: 1.8,
      EVENT: 1,
      SOCIAL: 1.5,
      OOH: 2.4
    }
  }
];

const TestPage = () => {
  // État général
  const [activeView, setActiveView] = useState<'discover' | 'my-hitshots' | 'request'>('discover');
  const [selectedHitShots, setSelectedHitShots] = useState<Set<number>>(new Set());
  const [isPlaying, setIsPlaying] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [isCartOpen, setIsCartOpen] = useState(false);

  // État du funnel de demande
  const [currentStep, setCurrentStep] = useState(0);
  const [requestDetails, setRequestDetails] = useState<RequestDetails>({
    selectedHitShots: new Set(),
    usageTypes: new Set(),
    territory: null,
    duration: null
  });

  // Gestionnaires d'événements
  const handleHitShotSelect = (id: number) => {
    const newSelection = new Set(selectedHitShots);
    if (newSelection.has(id)) {
      newSelection.delete(id);
    } else {
      newSelection.add(id);
    }
    setSelectedHitShots(newSelection);
    setRequestDetails(prev => ({
      ...prev,
      selectedHitShots: newSelection
    }));
  };

  const handleUsageChange = (usages: Set<UsageType>) => {
    setRequestDetails(prev => ({
      ...prev,
      usageTypes: usages
    }));
  };

  const handleTerritoryChange = (territory: TerritoryType) => {
    setRequestDetails(prev => ({
      ...prev,
      territory
    }));
  };

  const handleDurationChange = (duration: DurationType) => {
    setRequestDetails(prev => ({
      ...prev,
      duration
    }));
  };

  const validateStep = () => {
    const currentValidation = ValidationProcess[currentStep];
    const validationResult = currentValidation.validation(requestDetails);
    
    if (validationResult.valid) {
      setCurrentStep(prev => prev + 1);
    } else {
      // Afficher message d'erreur
      alert(validationResult.message);
    }
  };
    
    interface CompactHitShotCardProps {
        hitshot: HitShot;
        selectable: boolean;
        showStatus: boolean;
    }
  
  const CompactHitShotCard: React.FC<CompactHitShotCardProps> = ({ hitshot, selectable = false, showStatus = false }) => (
    <div 
      className={`relative overflow-hidden rounded-lg bg-gradient-to-r ${hitshot.gradient} p-4 
        shadow-lg hover:shadow-xl transition-all duration-300 
        ${selectable ? 'cursor-pointer' : ''} 
        ${selectedHitShots.has(hitshot.id) ? 'ring-2 ring-orange-400' : ''}`}
      onClick={() => selectable && handleHitShotSelect(hitshot.id)}
    >
      <div className="flex items-center gap-3">
        <button 
          onClick={(e) => {
            e.stopPropagation();
            setIsPlaying(!isPlaying);
          }}
          className="w-10 h-10 flex items-center justify-center bg-white/20 rounded-full
            hover:bg-white/30 transition-colors group"
        >
          {isPlaying ? 
            <Pause className="w-5 h-5 text-white" /> : 
            <Play className="w-5 h-5 text-white ml-1" />
          }
        </button>
        
        <div className="flex-1 min-w-0">
          <h3 className="text-white font-medium truncate">{hitshot.title}</h3>
          <p className="text-white/70 text-sm truncate">{hitshot.artist}</p>
        </div>

        {showStatus && hitshot.status && (
          <div className={`px-3 py-1 rounded-full text-xs font-medium ${STATUS[hitshot.status].color}`}>
            {STATUS[hitshot.status].label}
          </div>
        )}

        {selectable && (
          <div className={`absolute top-2 right-2 w-6 h-6 rounded-full border-2 
            ${selectedHitShots.has(hitshot.id) ? 'bg-orange-500 border-white' : 'border-white/50'} 
            flex items-center justify-center transition-all`}>
            {selectedHitShots.has(hitshot.id) && <CheckCircle size={14} className="text-white" />}
          </div>
        )}
      </div>
    </div>
  );

  const RequestFunnel = () => (
    <div className="max-w-4xl mx-auto">
      {/* Progress Steps */}
      <div className="flex items-center justify-between mb-8">
        {ValidationProcess.map((step, index) => (
          <div key={index} className="flex items-center">
            <div className={`flex items-center justify-center w-10 h-10 rounded-full 
              ${index <= currentStep ? 'bg-gradient-to-r from-orange-500 to-red-600 text-white' 
                : 'bg-gray-800 text-gray-500'}`}>
              <CheckCircle size={20} />
            </div>
            {index < ValidationProcess.length - 1 && (
              <div className={`w-24 h-1 mx-2 
                ${index < currentStep ? 'bg-orange-500' : 'bg-gray-800'}`} />
            )}
          </div>
        ))}
      </div>

      {/* Step Content */}
      <div className="bg-gray-900 rounded-xl p-6 border border-gray-800">
        {currentStep === 0 && (
          <div className="space-y-6">
            <h2 className="text-xl font-bold text-white mb-6">Sélectionnez vos HitShots</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              {myHitshots.map(hitshot => (
                <CompactHitShotCard 
                  key={hitshot.id} 
                  hitshot={hitshot} 
                  selectable={true}
                  showStatus={false}
                />
              ))}
            </div>
          </div>
        )}

        {currentStep === 1 && (
          <div className="space-y-6">
            <h2 className="text-xl font-bold text-white mb-6">Type d'utilisation</h2>
            <UsageSelector
              selectedUsages={requestDetails.usageTypes}
              onUsageChange={handleUsageChange}
            />
          </div>
        )}

        {currentStep === 2 && (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <h2 className="text-xl font-bold text-white mb-6">Territoire</h2>
                <div className="space-y-3">
                  {Object.entries(TERRITORIES).map(([key, config]) => (
                    <button
                      key={key}
                      onClick={() => handleTerritoryChange(key as TerritoryType)}
                      className={`w-full p-4 rounded-lg border text-left transition-all ${
                        requestDetails.territory === key
                          ? 'border-orange-500 bg-orange-500/10'
                          : 'border-gray-800 hover:border-orange-500/50'
                      }`}
                    >
                      <h3 className="text-white font-medium">{config.label}</h3>
                      <p className="text-sm text-gray-400">{config.description}</p>
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <h2 className="text-xl font-bold text-white mb-6">Durée</h2>
                <div className="space-y-3">
                  {Object.entries(DURATIONS).map(([key, config]) => (
                    <button
                      key={key}
                      onClick={() => handleDurationChange(key as DurationType)}
                      className={`w-full p-4 rounded-lg border text-left transition-all ${
                        requestDetails.duration === key
                          ? 'border-orange-500 bg-orange-500/10'
                          : 'border-gray-800 hover:border-orange-500/50'
                      }`}
                    >
                      <h3 className="text-white font-medium">{config.label}</h3>
                      {key === 'CUSTOM' ? (
                        <div className="mt-2 space-y-2">
                          <input
                            type="date"
                            className="w-full p-2 rounded bg-gray-800 text-white border border-gray-700"
                            placeholder="Date de début"
                          />
                          <input
                            type="date"
                            className="w-full p-2 rounded bg-gray-800 text-white border border-gray-700"
                            placeholder="Date de fin"
                          />
                        </div>
                      ) : (
                        <p className="text-sm text-gray-400">
                          Multiplicateur: x{config.multiplier}
                        </p>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <PricingCalculator
              selectedHitShots={selectedHitShots}
              selectedUsages={requestDetails.usageTypes}
              territory={requestDetails.territory}
              duration={requestDetails.duration}
            />
          </div>
        )}

        {currentStep === 3 && (
          <ContractSummary
            details={requestDetails}
            onValidate={() => {
              // Logique de validation finale
              alert('Demande envoyée avec succès !');
              setActiveView('my-hitshots');
              setCurrentStep(0);
            }}
          />
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-8">
          <button 
            onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
            className="px-6 py-2 border border-gray-700 rounded-lg text-gray-400 
              hover:border-orange-500 hover:text-white transition-colors"
            disabled={currentStep === 0}
          >
            Retour
          </button>
          {currentStep < ValidationProcess.length - 1 && (
            <button 
              onClick={validateStep}
              className="px-6 py-2 bg-gradient-to-r from-orange-500 to-red-600 
                text-white rounded-lg hover:opacity-90 transition-opacity"
            >
              Suivant
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-950">
      {/* Navigation existante... */}
      
      <main className="max-w-7xl mx-auto px-4 py-8">
        <RequestFunnel />
      </main>
    </div>
  );
};

export default TestPage;