import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  PenTool, 
  Users, 
  User, 
  LogOut, 
  Menu, 
  X, 
  BookOpen,
  Library,
} from 'lucide-react';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import { logout } from '../../api/auth-users';

interface SideMenuProps {
  children?: React.ReactNode;
}

export const SideMenu: React.FC<SideMenuProps> = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = async () => {
    setIsLoading(true);
    await logout();
    setIsLoading(false);
    window.location.href = '/login';
  };

  return (
    <div className="flex min-h-screen bg-black">
      {/* Menu latéral */}
      <aside 
        className={`
          fixed inset-y-0 left-0 w-72 
          bg-black/30 backdrop-blur-md
          border-r border-white/10 
          transition-transform duration-300 z-40
          ${isMobile ? (isOpen ? 'translate-x-0' : '-translate-x-full') : 'translate-x-0'}
        `}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="p-6 border-b border-white/10">
            <h1 className="text-2xl font-bold text-white/90 tracking-wider">
              <Link to="/" className="text-2xl font-bold text-white hover:text-purple-400 transition-colors">{t('general.infinistories')}</Link>
            </h1>
          </div>

          <nav className="flex-1 p-4 overflow-y-auto custom-scrollbar">
            <ul className="space-y-2">
              <li>
                <Link
                  to="/stories"
                  className={`
                    flex items-center gap-3 p-3 rounded-lg
                    transition-all duration-200
                    ${location.pathname === '/stories' 
                      ? 'bg-white/10 text-white' 
                      : 'text-white/70 hover:bg-white/5 hover:text-white'}
                  `}
                >
                  <Library size={20} />
                  <span>{t('menu.menu_home')}</span>
                </Link>
              </li>
              <li>
                <Link
                  to={isAuthenticated ? "/create" : "/login"}
                  className={`
                    flex items-center gap-3 p-3 rounded-lg
                    transition-all duration-200
                    ${location.pathname === '/create' 
                      ? 'bg-white/10 text-white' 
                      : 'text-white/70 hover:bg-white/5 hover:text-white'}
                  `}
                >
                  <PenTool size={20} />
                  <span>{t('menu.menu_create_adventure')}</span>
                </Link>
              </li>
              {isAuthenticated &&
                <li>
                  <Link
                    to="/my-stories"
                    className={`
                      flex items-center gap-3 p-3 rounded-lg
                      transition-all duration-200
                      ${location.pathname === '/my-stories' 
                        ? 'bg-white/10 text-white' 
                        : 'text-white/70 hover:bg-white/5 hover:text-white'}
                    `}
                  >
                    <BookOpen size={20} />
                    <span>{t('menu.menu_my_stories')}</span>
                  </Link>
                </li>
              }
              <li>
                <Link
                  to={isAuthenticated ? "/heroes" : "/login"}
                  className={`
                    flex items-center gap-3 p-3 rounded-lg
                    transition-all duration-200
                    ${location.pathname === '/heroes' 
                      ? 'bg-white/10 text-white' 
                      : 'text-white/70 hover:bg-white/5 hover:text-white'}
                  `}
                >
                  <Users size={20} />
                  <span>{t('menu.menu_my_heroes')}</span>
                </Link>
              </li> 
              {isAuthenticated && <li>
                <Link
                  to="/account"
                  className={`
                    flex items-center gap-3 p-3 rounded-lg
                    transition-all duration-200
                    ${location.pathname === '/account'
                      ? 'bg-white/10 text-white'
                      : 'text-white/70 hover:bg-white/5 hover:text-white'}
                  `}
                >
                  <User size={20} />
                  <span>{t('menu.menu_my_account')}</span>
                </Link>
              </li>}
            </ul>
          </nav>

          {/* Footer */}
          <div className="p-4 border-t border-white/10">
            {isAuthenticated ? (
              <button
                onClick={handleLogout}
                disabled={isLoading}
                className="
                  w-full flex items-center gap-3 p-3 rounded-lg
                  text-white/70 hover:bg-white/5 hover:text-white
                  transition-all duration-200
                "
              >
                <LogOut size={20} />
                <span>{isLoading ? t('auth.logging_out_inprogress') : t('auth.logout')}</span>
              </button>
            ) : (
              <div className="space-y-2">
                <Link
                  to="/auth"
                  className="
                    w-full flex items-center gap-3 p-3 rounded-lg
                    text-white/70 hover:bg-white/5 hover:text-white
                    transition-all duration-200
                  "
                >
                  <User size={20} />
                  <span>{t('auth.login')}</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </aside>

      {/* Contenu principal */}
      <main className={`
        flex-1 
        ${!isMobile ? 'ml-72' : 'ml-0'} 
        transition-all duration-300
      `}>
        {children}
      </main>

      {/* Bouton toggle mobile */}
      {isMobile && (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="
            fixed top-4 left-4 z-50
            p-2 rounded-lg
            bg-black/30 backdrop-blur-md
            border border-white/10
            text-white/70 hover:text-white
            transition-all duration-200
          "
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      )}

      {/* Overlay mobile */}
      {isMobile && isOpen && (
        <div
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-30"
          onClick={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};