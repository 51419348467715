import { Hero } from "../types/Hero";
import { axiosInstanceWithUserAuth } from "./axios-instance";

export const getUserHeroes = async () => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/heroes`, {
      action: "GET",
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching user heroes: ${error}`);
  }
};

export const createUserHero = async (heroData: Hero, avatar?: File) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/heroes`, {
      action: "CREATE",
      heroRequest: heroData,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating hero: ${error}`);
  }
};

export const updateUserHero = async (heroData: Hero, avatar?: File) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/heroes`, {
      action: "UPDATE",
      heroRequest: heroData,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating hero: ${error}`);
  }
};

export const deleteUserHero = async (heroUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/heroes`, {
      action: "DELETE",
      heroUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error deleting hero: ${error}`);
  }
};

export const generateHeroAvatar = async (heroUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/heroes`, {
      action: "GENERATE_AVATAR",
      heroUuid,
    });
    console.log(response.data?.url);
    return response.data;
  } catch (error) {
    throw new Error(`Error deleting hero: ${error}`);
  }
};
