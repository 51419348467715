import React from 'react';
import './TextLoading.css'

interface TextLoadingProps {
text: string;
variant?: 'gradient' | 'highlight' | 'typewriter' | 'shine' | 'underline' | 'wave' | 'pulse';
className?: string;
}

export const TextLoading: React.FC<TextLoadingProps> = ({ 
text, 
variant = 'typewriter',
className = '' 
}) => {
const renderLoading = () => {
    switch(variant) {
    case 'gradient':
        return (
        <span className={`${className} bg-gradient-to-r from-purple-500 via-fuchsia-500 to-purple-500 animate-gradient-x bg-clip-text text-transparent`}>
            {text}
        </span>
        );

    case 'highlight':
        return (
        <span className={className}>
            {text.split('').map((char, i) => (
            <span 
                key={i}
                className="inline-block animate-highlight"
                style={{ animationDelay: `${i * 0.1}s` }}
            >
                {char === ' ' ? '\u00A0' : char}
            </span>
            ))}
        </span>
        );

    case 'typewriter':
        return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <span 
              className={className}
              style={{
                display: 'block',
                whiteSpace: 'nowrap',
                borderRight: '4px solid rgb(139, 92, 246)',
                width: '0',
                animation: 'typing 3s steps(40, end) forwards, blink 0.75s step-end infinite',
                overflow: 'hidden'
              }}
            >
              {text}
            </span>
        </div>
        );

    case 'shine':
        return (
        <div className="relative inline-block">
            <span className={className}>
            {text}
            </span>
            <div className="absolute top-0 -inset-full h-full w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-transparent to-white/20 animate-shine" />
        </div>
        );

    case 'underline':
        return (
        <span 
            className={className}
            style={{
            backgroundImage: 'linear-gradient(to right, purple, fuchsia)',
            backgroundSize: '200% 2px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right bottom',
            paddingBottom: '2px'
            }}
        >
            {text}
        </span>
        );

    case 'wave':
        return (
        <span className={className}>
            {text.split('').map((char, i) => (
            <span 
                key={i}
                className="inline-block animate-wave"
                style={{ animationDelay: `${i * 0.1}s` }}
            >
                {char === ' ' ? '\u00A0' : char}
            </span>
            ))}
        </span>
        );

    case 'pulse':
        return (
        <span className={`${className} animate-pulse-text`}>
            {text}
        </span>
        );

    default:
        return <span className={className}>{text}</span>;
    }
};

return renderLoading();
};